import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import Index from "./components/Index";
import ProtectedRoute from "./Protected-Routes"; // Update with the actual path
import { AuthProvider } from "./Auth-Context";

import { LanguageProvider } from "./LanguageContext"; // Import the provider

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Products from "./components/Products";
import { getStorage } from "firebase/storage";

//STAR UK

const firebaseConfig = {
  apiKey: "AIzaSyBLM31GkpfrhZk-bL5fAbjoElLbsWosi8c",
  authDomain: "balance-b8f74.firebaseapp.com",
  projectId: "balance-b8f74",
  storageBucket: "balance-b8f74.appspot.com", // Update this with the correct bucket name
  messagingSenderId: "539123754606",
  appId: "1:539123754606:web:dec08adea9e9853d4ba87f",
  measurementId: "G-53R535VV72",
};

//KRG

/*
const firebaseConfig = {
  apiKey: "AIzaSyAoDoRdy14JjWqdYiTgWELWTJY67rTigMY",
  authDomain: "market-5258c.firebaseapp.com",
  projectId: "market-5258c",
  storageBucket: "market-5258c.appspot.com",
  messagingSenderId: "1037984080800",
  appId: "1:1037984080800:web:d92e9ba5b04b1d71087ac5",
  measurementId: "G-50FSVX08GY",
};
*/

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);

//const db = getFirestore(firebase,'testdata'); //this is second live tax fraud db
//const db = getFirestore(firebase,'testdb'); //this ones full test db


const storage = getStorage(firebase); // Initialize storage right after app


export { firebase, db, storage };

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/public" element={<Products />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/index" element={<Index />} />
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;
