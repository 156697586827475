//@ts-nocheck
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { CSSProperties, useEffect, useState } from "react";
import { db } from "../App";
import { formatBase64Image, processHistoryLogClient } from "./Functions";
import InvoiceDetailsModal from "./InvoiceDetailsModal"; // Import InvoiceDetailsModal

const ItemDetailsModal = ({ isOpen, onClose, item }: any) => {
  const [mode, setMode] = useState("details"); // 'details' or 'restock'
  const [additionalQuantity, setAdditionalQuantity] = useState(0);
  const [newUnitPrice, setNewUnitPrice] = useState(item.unitPrice || 0);
  const [newQuantity, setNewQuantity] = useState(item.quantity || 0); // Added state for quantity

  const [newVat, setNewVat] = useState(item.vat || false);

  const [newName, setNewName] = useState(item.name || "");
  const [newDescription, setNewDescription] = useState(item.description || "");
  const [newBarcode, setNewBarcode] = useState(item.barcode || "");

  const [newCategory, setNewCategory] = useState(item.category || "");
  const [categories, setCategories] = useState<string[]>([]);
  const [existingBarcodes, setExistingBarcodes] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [newPriority, setNewPriority] = useState(item.priority || 0); // New state for priority

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false); // State to control InvoiceDetailsModal visibility
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null); // State to store selected invoice ID

  const [isProcessing, setIsProcessing] = useState(false);

  const [newImage, setNewImage] = useState(item.imageUrl || ""); // State to handle the image

  const [historyData, setHistoryData] = useState([]);

  const [loadingStores, setLoadingStores] = useState({}); // Store loading states
  const [storeDetails, setStoreDetails] = useState({});

  const loadStoreDetails = async (storeId) => {
    if (!storeId) {
      console.error("storeId is undefined or null");
      return;
    }

    //console.log("STORE NOWNOW", storeId); // Log the storeId being checked

    if (storeDetails[storeId] || loadingStores[storeId]) return;

    console.log(`Loading store details for storeId: ${storeId}`);

    setLoadingStores((prev) => ({ ...prev, [storeId]: true }));

    const storeDocRef = doc(db, "Prospects", storeId);
    const storeDocSnap = await getDoc(storeDocRef);
    if (storeDocSnap.exists()) {
      console.log(
        `Store details found for storeId: ${storeId}`,
        storeDocSnap.data()
      );
      setStoreDetails((prev) => ({ ...prev, [storeId]: storeDocSnap.data() }));
    } else {
      console.log(`No store details found for storeId: ${storeId}`);
      setStoreDetails((prev) => ({
        ...prev,
        [storeId]: { name: "Unknown Store" },
      }));
    }

    setLoadingStores((prev) => ({ ...prev, [storeId]: false }));
  };

  

  useEffect(() => {
    const handleScroll = () => {
      console.log('Handle scroll triggered');
      const historyEntries = document.querySelectorAll(".historyEntry");
      historyEntries.forEach((entry) => {
        const storeId = entry.getAttribute("data-storeid");
        console.log('Checking storeId in scroll:', storeId);
        if (entry.getBoundingClientRect().top < window.innerHeight) {
          loadStoreDetails(storeId);
        }
      });
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [historyData]);
  
  
  useEffect(() => {
    if (historyData.length > 0) {
      historyData.forEach((historyEntry) => {
        const user = historyEntry.actionByUid;
        //console.log('Calling loadStoreDetails for storeId:', user);
        loadStoreDetails(user);
      });
    }
  }, [historyData]);
  

  const handleInvoiceClick = (invoiceId: string) => {
    setSelectedInvoiceId(invoiceId);
    setInvoiceModalOpen(true);
  };

  
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Categories"), (snapshot) => {
      const fetchedCategories = snapshot.docs.map((doc) => doc.data().Category);
      setCategories(fetchedCategories);
    });

    return () => unsubscribe();
  }, []);
  

  const [userUid, setUserUid] = useState("admin");

  /*
  useEffect(() => {
    const fetchBarcodes = async () => {
      if (!isOpen) return;

      setIsUpdating(true);
      const querySnapshot = await getDocs(collection(db, "Warehouse"));
      const fetchedBarcodes = querySnapshot.docs.map(
        (doc) => doc.data().barcode
      );
      setExistingBarcodes(
        fetchedBarcodes.filter((barcode) => barcode !== item.barcode)
      ); // Exclude the current item's barcode
      setIsUpdating(false);
    };

    fetchBarcodes();
  }, [isOpen]);
  */


  const fetchHistoryData = async () => {
    if (!item || !item.id) return;

    setIsLoadingHistory(true);
    const historyDocRef = doc(db, "ProcessHistory", item.id);
    try {
      const docSnap = await getDoc(historyDocRef);
      if (docSnap.exists()) {
        const historyData = docSnap.data().history; // Assuming 'history' is the field containing the history entries
        setHistoryData(historyData);
        console.log(historyData);
      } else {
        setHistoryData([]); // No document found
      }
    } catch (error) {
      console.error("Error fetching history data: ", error);
      setHistoryData([]); // Handle the error by setting no data
    } finally {
      setIsLoadingHistory(false);
    }
  };


  const ViewHistory = async () => {
    fetchHistoryData();

    setMode("history");
  };

  const handleArchive = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }

    // Check if the item's reserved quantity is more than 0
    if (item.reserved > 0) {
      alert(`Cannot archive ${item.name} as it has reserved quantities.`);
      return;
    }

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, {
        archived: true, // Set the 'archived' field to true
      });
      alert(`Successfully archived ${item.name}.`);
      onClose(); // Close the modal after archiving
    } catch (error) {
      console.error("Error archiving document: ", error);
      alert("Failed to archive item.");
    }
  };

  const handleUnarchive = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }
    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, { archived: false });
      alert(`Successfully unarchived ${item.name}.`);
      onClose();
    } catch (error) {
      console.error("Error unarchiving item: ", error);
      alert("Failed to unarchive item.");
    }
  };

  const handleDelete = async () => {
    if (!item || !item.id) {
      alert("Item details are missing.");
      return;
    }

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await deleteDoc(itemRef);
      alert(`Successfully deleted ${item.name}.`);
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting document: ", error);
      alert("Failed to delete item.");
    }
  };

  const handleUpdateDetails = async () => {
    // Validation checks for unit price
    if (newUnitPrice <= 0 || !/^\d+(\.\d{0,2})?$/.test(String(newUnitPrice))) {
      alert("Please enter a valid unit price with up to two decimal places.");
      return;
    }
    // Validation for barcode uniqueness
    if (newBarcode && existingBarcodes.includes(newBarcode)) {
      alert(
        "The barcode already exists in the warehouse. Please use a unique barcode."
      );
      return;
    }
    // Validation for quantity (allowing zero but checking against reserved quantity)
    if (!Number.isInteger(newQuantity) || newQuantity < 0) {
      alert("Please enter a valid non-negative integer for the quantity.");
      return;
    }
    if (item.reserved > newQuantity) {
      alert(
        `The new quantity cannot be less than the reserved quantity (${item.reserved}).`
      );
      return;
    }

    setIsProcessing(true); // Disable the button

    // Document reference
    const itemRef = doc(db, "Warehouse", item.id);
    try {
      // Update the document
      await updateDoc(itemRef, {
        name: newName,
        description: newDescription,
        barcode: newBarcode,
        category: newCategory,
        unitPrice: newUnitPrice,
        quantity: newQuantity,
        vat: newVat, // Include VAT field in the update
        imageUrl: newImage, // Update the image URL
        priority: newPriority, // Include priority in the update
      });

      // Optionally log the update
      await processHistoryLogClient(item.id, userUid, "item_update", {
        quantity: newQuantity,

        prevDetails: {
          name: item.name,
          description: item.description,
          barcode: item.barcode,
          category: item.category,
          price: item.unitPrice,
          quantity: item.quantity,
          vat: item.vat, // Include previous VAT status in the log
          imageUrl: item.imageUrl, // Log previous image
        },
        newDetails: {
          name: newName,
          description: newDescription,
          barcode: newBarcode,
          category: newCategory,
          price: newUnitPrice,
          quantity: newQuantity,
          vat: newVat, // Include new VAT status in the log
          imageUrl: newImage, // Log new image
          priority: newPriority, // Include priority in the update
        },
        additionalInfo: { updatedBy: userUid },
      });

      alert(`Details updated successfully for ${item.name}.`);
      onClose();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to update item details.");
    } finally {
      setIsProcessing(false); // Re-enable the button
    }
  };

  const resizeImage = (
    img: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
    callback: (resizedDataUrl: string) => void
  ) => {
    const canvas = document.createElement("canvas");
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d")!;
    ctx.drawImage(img, 0, 0, width, height);
    callback(canvas.toDataURL());
  };

  const handleImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          resizeImage(img, 500, 500, (resizedDataUrl) => {
            const base64WithoutPrefix = resizedDataUrl.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            // Determine the correct prefix based on the file type
            const fileType = file.type;
            let prefix = "data:image/jpeg;base64,";
            if (fileType === "image/png") {
              prefix = "data:image/png;base64,";
            }

            setNewImage(prefix + base64WithoutPrefix); // Use setNewImage instead of setImage
          });
        };
        img.src = e.target!.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRestock = async () => {
    if (!Number.isInteger(additionalQuantity) || additionalQuantity <= 0) {
      alert("Please enter a valid positive integer quantity to add.");
      return;
    }

    setIsProcessing(true); // Disable the button

    // Calculate the new quantity after restock
    const newQuantity = item.quantity + additionalQuantity;

    const itemRef = doc(db, "Warehouse", item.id);
    try {
      await updateDoc(itemRef, { quantity: newQuantity });

      // After successfully updating item quantity in handleRestock
      await processHistoryLogClient(item.id, userUid, "restock", {
        prevQuantity: item.quantity,
        quantity: newQuantity,
        additionalInfo: { restockedBy: userUid, additionalQuantity },
      });

      alert(
        `Successfully restocked ${item.name} with ${additionalQuantity} units.`
      );
      onClose();
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("Failed to restock item.");
    } finally {
      setIsProcessing(false); // Re-enable the button
    }
  };

  if (!isOpen) return null;

  const labelStyle: React.CSSProperties = {
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 15,
    padding: "15px 10px", // Twice the padding vertically compared to horizontally
    borderRadius: "15px",
    maxWidth: "60%",
  };

  //console.log(item.name)

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        {mode === "details" && (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <h2>Item Details</h2>
                <p>Name: {item.name}</p>
                <p>Category: {item.category}</p>
                <p>Description: {item.description}</p>
                <p>barcode: {item.barcode}</p>
                <p>ID: {item.id}</p>
                <p>Unit Price: £{item.unitPrice}</p>
                <p>VAT: {item.vat ? "Yes" : "No"}</p>

                <p
                  style={{
                    ...labelStyle,
                    backgroundColor: "#FFCC00",
                    color: "white",
                  }}
                >
                  Reserve: {item.reserved !== undefined ? item.reserved : 0}
                </p>
                <p
                  style={{
                    ...labelStyle,
                    backgroundColor: item.quantity === 0 ? "red" : "green",
                    color: "white",
                  }}
                >
                  Quantity: {item.quantity}
                </p>
                <p
                  style={{
                    ...labelStyle,
                    backgroundColor:
                      item.quantity - item.reserved === 0 ? "red" : "green",
                    color: "white",
                  }}
                >
                  Available Quantity:{" "}
                  {item.reserved !== undefined
                    ? item.quantity - item.reserved
                    : item.quantity}
                </p>
              </div>

              <div style={{ flex: 1, textAlign: "center" }}>
                {item.imageUrl && (
                  <img
                    src={formatBase64Image(item.imageUrl)}
                    alt="Item Image"
                    style={{
                      width: "80%", // Set image width to 100% of its container
                      maxHeight: "80%",
                      margin: 30,
                      borderRadius: "10px", // Rounded corners for the image
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                    }}
                  />
                )}
              </div>
            </div>

            <button style={modalStyles.closeButton} onClick={onClose}>
              Close
            </button>
            {item.archived ? (
              <button style={modalStyles.button} onClick={handleUnarchive}>
                Unarchive Item
              </button>
            ) : (
              <>
                <button
                  style={modalStyles.button}
                  onClick={() => ViewHistory()}
                >
                  View History
                </button>
                <button
                  style={modalStyles.button}
                  onClick={() => setMode("restock")}
                >
                  Restock Item
                </button>
                <button
                  style={modalStyles.button}
                  onClick={() => setMode("price")}
                >
                  Update Item Details
                </button>
                <button style={modalStyles.button} onClick={handleArchive}>
                  Archive Item
                </button>
              </>
            )}
          </>
        )}
        {mode === "restock" && (
          <>
            <h2>Restock {item.name}</h2>
            <input
              type="number"
              placeholder="Additional Quantity"
              value={additionalQuantity}
              onChange={(e) => setAdditionalQuantity(Number(e.target.value))}
            />
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>
            <button
              style={modalStyles.button}
              onClick={handleRestock}
              disabled={isProcessing} // Disable while processing
            >
              Update Quantity
            </button>
          </>
        )}
        {mode === "price" && (
          <>
            <h2>Update Item Details {item.name}</h2>
            <div style={labelStyle}>Name:</div>
            <input
              type="text"
              placeholder="Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Description:</div>
            <textarea
              placeholder="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Barcode:</div>
            <input
              type="text"
              placeholder="Barcode"
              value={newBarcode}
              onChange={(e) => setNewBarcode(e.target.value)}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Category:</div>
            <select
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              style={modalStyles.inputStyles}
            >
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <div style={labelStyle}>Unit Price:</div>
            <input
              type="number"
              placeholder="New Unit Price"
              value={newUnitPrice}
              onChange={(e) => setNewUnitPrice(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />
            <div style={labelStyle}>Quantity:</div>
            <input
              type="number"
              placeholder="Quantity"
              value={newQuantity}
              onChange={(e) => setNewQuantity(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />

            <div style={labelStyle}>Priority:</div>
            <input
              type="number"
              placeholder="Priority (Lower number = Higher priority)"
              value={newPriority}
              onChange={(e) => setNewPriority(Number(e.target.value))}
              style={modalStyles.inputStyles}
            />

            <div style={labelStyle}>Image:</div>
            {newImage && (
              <img
                src={newImage}
                alt="Item Image"
                style={{
                  width: "30%",
                  maxHeight: "30%",
                  margin: 30,
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            )}
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              style={modalStyles.inputStyles}
            />
            <div
              style={{
                ...labelStyle,
                display: "flex",
                alignItems: "center",
                marginBottom: "40px",
              }}
            >
              <label>VAT ITEM:</label>
              <input
                type="checkbox"
                checked={newVat}
                onChange={(e) => setNewVat(e.target.checked)}
                style={{ marginRight: "10px" }}
              />
            </div>
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>
            <button
              style={modalStyles.button}
              onClick={handleUpdateDetails}
              disabled={isProcessing} // Disable while processing
            >
              Update Item
            </button>
          </>
        )}

        {mode === "history" && (
          <>
            <h2>Item History</h2>
            {isLoadingHistory ? (
              <div className="spinner-large"></div>
            ) : (
              <>
                {historyData.length > 0 ? (
                  historyData.map((historyEntry, index) => {
                    const date = new Date(historyEntry.timestamp);
                    const formattedDate = date.toLocaleString(); // Format the timestamp

                    // Extract the common data
                    const action = historyEntry.actionType;
                    const user = historyEntry.actionByUid;
                    const reservedStock = historyEntry.details.newReserved || 0;
                    const warehouseStock =
                      historyEntry.details.quantity || "error";

                    const availableStock = warehouseStock - reservedStock;

                    const prevReserved =
                      historyEntry.details.previousReserved || 0;
                    const prevQuantity =
                      historyEntry.details.previousQuantity ||
                      item.quantity ||
                      0;

                    // Calculate differences
                    const reservedDiff = reservedStock - prevReserved;
                    const denyDiff = prevReserved - reservedStock;

                    const quantityDiff = warehouseStock - prevQuantity;
                    const acceptDiff = prevQuantity - warehouseStock;

                    // Determine circle colors
                    const reservedColor =
                      reservedStock === 0 ? "grey" : "#FFCC00";
                    const warehouseColor =
                      warehouseStock === 0 ? "red" : "blue";
                    const availableColor =
                      availableStock === 0 ? "red" : "green";

                    // Determine the action amount
                    let actionAmount = 0;
                    if (action === "reserve") {
                      actionAmount = reservedDiff;
                    } else if (action === "create") {
                      actionAmount = warehouseStock;
                    } else if (action === "accept_invoice") {
                      actionAmount = acceptDiff;
                    } else if (action === "deny_invoice") {
                      actionAmount = denyDiff;
                    } else if (action === "unreserve") {
                      actionAmount = denyDiff;
                    } else if (action === "restock_item/void invoice") {
                      actionAmount = quantityDiff;
                    }

                    const store = storeDetails[user];
                    const isLoading = loadingStores[user];

                    return (
                      <div
                        key={index}
                        style={modalStyles.historyEntry}
                        data-storeid={user}
                      >
                        <div style={modalStyles.historyRow}>
                          <div style={modalStyles.historyLeft}>
                            <div style={modalStyles.actionDateContainer}>
                              <p style={modalStyles.historyAction}>{action}</p>
                              <p>{formattedDate}</p>
                              {action === "accept_invoice" && (
                                <button
                                  style={modalStyles.invoiceButton}
                                  onClick={() =>
                                    handleInvoiceClick(
                                      historyEntry.details.invoiceId
                                    )
                                  }
                                >
                                  {historyEntry.details.invoiceId}
                                </button>
                              )}
                            </div>
                            <p style={modalStyles.historyUser}>User: {user}</p>

                            {user !== "admin" &&
                              (isLoading ? (
                                <p>Loading...</p>
                              ) : store ? (
                                <>
                                  <p style={modalStyles.historyUser}>
                                    Store: {store.name}
                                  </p>
                                  <p style={modalStyles.historyUser}>
                                    Address: {store.address}
                                  </p>
                                  <p style={modalStyles.historyUser}>
                                    Email: {store.email}
                                  </p>
                                  <p style={modalStyles.historyUser}>
                                    Number: {store.phoneNumber}
                                  </p>
                                </>
                              ) : (
                                <p>Empty...</p>
                              ))}
                          </div>
                          <div style={modalStyles.historyRight}>
                            <div>
                              <div style={modalStyles.historyLabel}>
                                Action:
                              </div>
                              <div
                                style={{
                                  ...modalStyles.circle,
                                  backgroundColor: "purple",
                                }}
                              >
                                {actionAmount}
                              </div>
                            </div>
                            <div>
                              <div style={modalStyles.historyLabel}>
                                Reserved:
                              </div>
                              <div
                                style={{
                                  ...modalStyles.circle,
                                  backgroundColor: reservedColor,
                                }}
                              >
                                {reservedStock}
                              </div>
                            </div>
                            <div>
                              <div style={modalStyles.historyLabel}>
                                Warehouse:
                              </div>
                              <div
                                style={{
                                  ...modalStyles.circle,
                                  backgroundColor: warehouseColor,
                                }}
                              >
                                {warehouseStock}
                              </div>
                            </div>
                            <div>
                              <div style={modalStyles.historyLabel}>
                                Available:
                              </div>
                              <div
                                style={{
                                  ...modalStyles.circle,
                                  backgroundColor: availableColor,
                                }}
                              >
                                {availableStock}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No history data available.</p>
                )}
              </>
            )}
            <button
              style={modalStyles.closeButton}
              onClick={() => setMode("details")}
            >
              Back to Details
            </button>
          </>
        )}
      </div>
      {invoiceModalOpen && (
        <InvoiceDetailsModal
          isOpen={invoiceModalOpen}
          onClose={() => setInvoiceModalOpen(false)}
          invoiceId={selectedInvoiceId} // Pass the selected invoice ID
        />
      )}
    </div>
  );
};

export default ItemDetailsModal;

const modalStyles = {
  invoiceButton: {
    fontSize: "12px",
    marginLeft: "10px",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
    padding: "5px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "#0056b3",
    },
  } as CSSProperties,

  historyRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0", // Add padding for each row
  } as CSSProperties,

  historyLeft: {
    flex: 0.7,
    paddingRight: "10px", // Add padding to the right
  } as CSSProperties,

  actionDateContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline", // Align items to baseline for better vertical alignment
    fontSize: "14px", // Slightly smaller text size
  } as CSSProperties,

  historyUser: {
    fontSize: "14px", // Slightly smaller text size
  } as CSSProperties,

  historyRight: {
    flex: 1,
    display: "flex",
    justifyContent: "space-around", // Center items horizontally
    textAlign: "center", // Center align the text
    fontSize: "12px", // Smaller text size
    paddingLeft: "10px", // Add padding to the left
  } as CSSProperties,

  historyLabel: {
    fontWeight: "bold",
    padding: "2px 5px",
    borderRadius: "5px",
    color: "black",
  } as CSSProperties,

  circleContainer: {
    display: "flex",
    alignItems: "center",
  } as CSSProperties,

  circle: {
    display: "inline-block",
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    borderRadius: "50%",
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    marginLeft: "5px", // Add space between the diff and circle
  } as CSSProperties,

  diff: {
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "5px", // Add space between the diff and circle
  } as CSSProperties,

  // Other styles...

  detailsHeading: {
    fontSize: "20px", // Increase font size
    fontWeight: 700,
    marginBottom: "5px", // Add some margin below
  } as CSSProperties,

  historyEntry: {
    fontSize: "22px", // Increase font size
    fontWeight: 800,
    padding: "15px",
    margin: "10px 0",
    backgroundColor: "#f7f7f7", // Light grey background for each entry
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column", // Stack children vertically
    gap: "5px", // Space between children
  } as CSSProperties,

  // Style for the Action text
  historyAction: {
    fontWeight: 800,
    color: "#0056b3", // Primary color for the action text
    marginBottom: "4px", // Space below the action text
  } as CSSProperties,

  detailsHeading: {
    fontSize: "20px", // Increase font size
    fontWeight: 700,
    marginBottom: "5px", // Add some margin below
  } as CSSProperties,

  historyEntry: {
    fontSize: "22px", // Increase font size
    fontWeight: 800,
    padding: "15px",
    margin: "10px 0",
    backgroundColor: "#f7f7f7", // Light grey background for each entry
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column", // Stack children vertically
    gap: "5px", // Space between children
  } as CSSProperties,

  // Style for the Action text
  historyAction: {
    fontWeight: 800,
    color: "#0056b3", // Primary color for the action text
    marginBottom: "4px", // Space below the action text
  } as CSSProperties,

  // Style for the Details text
  historyDetails: {
    fontSize: "14px",
    lineHeight: "1.4", // Line height for better readability
    color: "#333", // Dark color for details text
    backgroundColor: "#fff", // White background for the details section
    padding: "10px",
    borderRadius: "6px", // Rounded corners for the details box
    border: "1px solid #dedede", // Border for the details box
  } as CSSProperties,

  closeButton: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#6c757d",
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,
  button: {
    fontSize: "16px",
    marginRight: 10,
    fontWeight: "600",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "12px 15px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease", // Smooth transition for hover effect
    "&:hover": {
      backgroundColor: "#0056b3", // Darker shade on hover
    },
  } as CSSProperties,

  inputStyles: {
    borderRadius: "12px", // Softer edges
    border: "1px solid #ced4da", // Lighter border color
    padding: "15px", // Increased padding
    marginTop: "12px", // Consistent margin top
    marginBottom: "12px", // Consistent margin bottom
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Subtle inner shadow for depth
    width: "calc(100% - 30px)", // Adjust width to account for padding
    fontSize: "14px", // Slightly larger font size for readability
  } as CSSProperties,
  headerText: {
    fontSize: "18px", // Larger font size for headers
    fontWeight: "bold", // Bold font weight for emphasis
    color: "#333", // Dark color for text
    textAlign: "center", // Center-align the text
    margin: "10px 0", // Margin for spacing above and below the text
    padding: "5px", // Optional padding
    marginBottom: 30,
    borderBottom: "1px solid #ddd", // Optional bottom border for a subtle line separator
  } as CSSProperties,
  centeredMessage: {
    display: "flex",
    fontSize: 30,
    fontWeight: "700",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    height: "100%", // Take full height of the container
    textAlign: "center", // Center text alignment
  } as CSSProperties,
  vehicleIcon: {
    width: "110px", // Set both width and height to the same value for a square shape
    height: "70px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "30%", // Circular shape
    objectFit: "cover",
    paddingLeft: 20,
    paddingRight: 20,
  } as CSSProperties,
  profileIcon: {
    width: "100px", // Set both width and height to the same value for a square shape
    height: "100px", // This ensures a fixed aspect ratio (1:1) for a square
    borderRadius: "20%", // Circular shape
    marginBottom: "10px",
    objectFit: "cover",
  } as CSSProperties,
  additionalContentContainer: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  leftSide: {
    width: "50%",
    marginRight: 30,
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,

    border: "1px solid #000000",
  } as CSSProperties,
  rightSide: {
    width: "50%",
    marginBottom: "20px",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    border: "1px solid #000000",
  } as CSSProperties,
  providerDetailRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0", // Add padding for each row if needed
    // Add any additional styling you need for each detail row
  } as CSSProperties,

  barContainer: {
    //width: '60%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
    padding: 40,
    border: "1px solid #000000",
    borderRadius: 10,
    //backgroundColor:'pink'
  } as CSSProperties,
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "33.3333%", // Divide the bar container into three equal parts
  } as CSSProperties,

  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  } as CSSProperties, // Assert the entire object as CSSProperties
  container: {
    backgroundColor: "white",
    padding: "25px",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    maxWidth: "720px", // Set a max width for larger screens
    width: "90%", // Responsive width
    maxHeight: "85vh",
    overflowY: "auto",
  } as CSSProperties,
  contentStyles: {
    // Define your styles here
    marginBottom: "10px",
  } as CSSProperties,
};
