// components/LoginForm.tsx
import React, { useEffect, useState } from "react";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import "./LoginForm.css";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("admin@apsoft.com");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(""); // State to store login errors
  const [isLoading, setIsLoading] = useState(false); // State to store loading status

  const navigate = useNavigate(); // Initialize useNavigate
  const auth = getAuth();

  const handleLogin = async () => {
    setLoginError("");
    setIsLoading(true); // Start loading
    try {
      // Set session persistence for this authentication session
      await setPersistence(auth, browserSessionPersistence);

      //log in the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      navigate("/index"); // Use navigate to redirect
    } catch (error: any) {
      console.error("Login error:", error);
      setLoginError(error.code); // Log the error code for debugging
      
      // Handle specific error codes
      if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
        setLoginError("Incorrect email or password. Please try again."); // Set error message for incorrect credentials
      } else if (error.code === "auth/too-many-requests") {
        setLoginError("Too many requests. Please try again later."); // Set error message for too many requests
      } else {
        setLoginError("An error occurred during login. Please try again."); // Set a general error message for other errors
      }
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  useEffect(() => {
    // Check if the user is already authenticated and redirect to index page
    const auth = getAuth();
    const currentUser = auth.currentUser;
    console.log("this" + currentUser);
    if (currentUser) {
      navigate("/index");
    }
  }, [navigate]);

  return (
    <div>
      <TopBar />
      <form className="centered-container" onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
        <h2>Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          onClick={handleLogin}
          disabled={isLoading}
          style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? <div className="spinner-small"></div> : "Login"}
        </button>
        </form>

        {loginError && <p className="login-error">{loginError}</p>}{" "}
        {/* Display error message if there is one */}
      <p className="copyright-text">
        Copyright © 2022 - 2024 AP-SOFT Operating Company, LLC. All Rights
        Reserved.
      </p>
    </div>
  );
};

export default LoginForm;
