//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { db } from "../App";
import TitleBar from "./TitleBar";
import { jsPDF } from "jspdf";

import { kurdishFont, arabicFont } from "./fonts64";

import html2canvas from "html2canvas";
import InvoiceDetailsModal from "./InvoiceDetailsModal";

import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

import bIcon from "../star.jpeg";
import {
  formatEpochDate,
  formatEpochTime,
  processHistoryLogClient,
} from "./Functions";

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState([]);
  const [activeTab, setActiveTab] = useState("Pending");
  const [isLoading, setIsLoading] = useState(true);
  const [prospects, setProspects] = useState([]);
  const [selectedStore, setSelectedStore] = useState(""); // State to store the selected store
  const [isListView, setIsListView] = useState(false); // State to toggle between list and grid view

  const [isAdding, setIsAdding] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  //const [selectedInvoice, setSelectedInvoice] = useState(null);
  //const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const openDetailsModal = (invoice: any) => {
    setSelectedInvoice(invoice);
    setIsDetailsModalOpen(true);
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const denyInvoice = async (invoice: any) => {
    if (!invoice || !invoice.items) return;

    setIsProcessing(true);

    try {
      for (const item of invoice.items) {
        const itemRef = doc(db, "Warehouse", item.docId);
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
          const itemData = itemSnap.data();
          const newReserved = (itemData.reserved || 0) - item.quantity;

          // Update the Warehouse item's reserved count only
          await updateDoc(itemRef, {
            reserved: newReserved,
          });

          // Update ProcessHistory for the item
          await processHistoryLogClient(item.docId, "admin", "deny_invoice", {
            previousReserved: itemData.reserved,
            newReserved: newReserved,
            quantity: itemData.quantity,
            invoiceId: invoice.id,
            additionalInfo: { deniedBy: "admin" },
          });
        }
      }

      // Mark the invoice as denied
      const invoiceRef = doc(db, "Invoices", invoice.id);
      await updateDoc(invoiceRef, {
        status: "Denied",
      });

      alert(`Invoice ${invoice.id} denied successfully.`);
    } catch (error) {
      console.error("Error denying invoice: ", error);
      alert(`Failed to deny invoice ${invoice.id}.`);
    } finally {
      setIsProcessing(false);
    }
  };

  const acceptInvoice = async (invoice) => {
    if (!invoice || !invoice.items) return;

    setIsProcessing(true);

    const batch = writeBatch(db); // Initialize a batch write

    try {
      for (const item of invoice.items) {
        const itemRef = doc(db, "Warehouse", item.docId);
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
          const itemData = itemSnap.data();
          const newQuantity = (itemData.quantity || 0) - item.quantity;
          const newReserved = (itemData.reserved || 0) - item.quantity;

          // Add the update operation to the batch
          batch.update(itemRef, {
            quantity: newQuantity,
            reserved: newReserved,
          });

          // If ProcessHistoryLogClient also updates Firestore, consider integrating it into the batch or ensuring it handles errors appropriately.
          processHistoryLogClient(item.docId, "admin", "accept_invoice", {
            previousQuantity: itemData.quantity,
            quantity: newQuantity,
            reserved: itemData.reserved,
            newReserved: itemData.reserved - item.quanitity,
            invoiceId: invoice.id,
            additionalInfo: { acceptedBy: "admin" },
          });
        }
      }

      // Mark the invoice as accepted and add it to the batch
      const invoiceRef = doc(db, "Invoices", invoice.id);
      batch.update(invoiceRef, {
        status: "Accepted",
      });

      // Commit the batch
      await batch.commit();
      alert(`Invoice ${invoice.id} accepted successfully.`);
    } catch (error) {
      console.error("Error accepting invoice: ", error);
      alert(`Failed to accept invoice ${invoice.id}.`);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(collection(db, "Prospects"), (snapshot) => {
      const prospectsList = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((prospect) => !prospect.archived); // Filter out archived prospects
      setProspects(prospectsList);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(collection(db, "Invoices"), (snapshot) => {
      let invoicesList = snapshot.docs
        .map((docSnapshot) => {
          const invoiceData = docSnapshot.data();
          // Directly use data from the invoice, no need to fetch from Warehouse
          /*
          if (invoiceData.archived) {
            return null; // Skip archived invoices
          }*/

          // Assuming invoiceData.items already contains the necessary details
          // like name, price, and quantity
          return {
            id: docSnapshot.id,
            ...invoiceData,
            items: invoiceData.items.map((item: any, index: any) => ({
              ...item,
              // Use the item's details directly without additional fetching
            })),
          };
        })
        .filter((invoice) => invoice !== null); // Remove null entries (archived invoices)

      invoicesList = invoicesList.sort(
        (a, b) => b.timestamp.seconds - a.timestamp.seconds
      );

      setInvoices(invoicesList);
      console.log(invoicesList[0]);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleArchiveInvoice = async (invoice: any) => {
    if (!invoice) return;

    const confirmArchive = window.confirm(
      "Are you sure you want to archive this invoice?"
    );
    if (confirmArchive) {
      try {
        const invoiceRef = doc(db, "Invoices", invoice.id);

        // Check if invoice is neither accepted nor denied
        if (invoice.status !== "Accepted" && invoice.status !== "Denied") {
          // Deny the invoice first
          await denyInvoice(invoice);
        }

        // Then, archive the invoice
        await updateDoc(invoiceRef, {
          archived: true,
        });

        alert("Invoice has been successfully archived.");
      } catch (error) {
        console.error("Error archiving invoice: ", error);
        alert("Failed to archive the invoice.");
      }
    }
  };

  const handleVoidInvoice = async (invoice: any) => {
    if (!invoice) return;

    const confirmVoid = window.confirm(
      "Are you sure you want to void this invoice? This action cannot be undone."
    );
    if (confirmVoid) {
      setIsProcessing(true);

      try {
        const invoiceRef = doc(db, "Invoices", invoice.id);

        // Update the invoice document to mark it as voided and remove the archived field
        await updateDoc(invoiceRef, {
          archived: false,
          void: true,
        });

        // Check if the invoice was previously accepted
        if (invoice.status === "Accepted") {
          // Restock the items in the warehouse, passing the invoice ID
          await restockItems(invoice.items, invoice.id);
        }

        alert("Invoice has been successfully voided.");
      } catch (error) {
        console.error("Error voiding invoice: ", error);
        alert("Failed to void the invoice.");
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const restockItems = async (items: any[], invoiceId: string) => {
    try {
      for (const item of items) {
        const itemRef = doc(db, "Warehouse", item.docId);
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
          const itemData = itemSnap.data();
          const newQuantity = (itemData.quantity || 0) + item.quantity;

          // Update the Warehouse item's quantity
          await updateDoc(itemRef, {
            quantity: newQuantity,
          });

          alert(
            `Updating item: ${itemData.name}\nBarcode: ${
              itemData.barcode
            }\nPrevious Quantity: ${itemData.quantity || 0}\nQuantity Added: ${
              item.quantity
            }\nNew Quantity: ${newQuantity}`
          );

          // Update ProcessHistory for the item, including the invoice ID
          await processHistoryLogClient(
            item.docId,
            "admin",
            "restock_item/void invoice",
            {
              previousQuantity: itemData.quantity,
              quantity: newQuantity,
              invoiceId: invoiceId,
              additionalInfo: { voidedBy: "admin" },
            }
          );
        }
      }
    } catch (error) {
      console.error("Error restocking items: ", error);
      throw error;
    }
  };

  const downloadInvoiceBase = (invoice: any) => {
    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15); // Reduced image size

    // Set up some initial positions
    let yPos = 35; // Adjusted yPos
    const lineHeight = 8; // Reduced line height

    // Invoice ID below the line
    pdf.text(`Invoice ID: ${invoice.id}`, 10, yPos);
    yPos += lineHeight; // Move to next line for store name

    // Convert Firestore Timestamp to JavaScript Date object for the date
    const date = invoice.timestamp
      ? new Date(invoice.timestamp.seconds * 1000)
      : null;
    const dateString = date
      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      : "No timestamp";

    pdf.text(`Date: ${dateString}`, 10, yPos);

    yPos += lineHeight; // Move to next line for store name

    pdf.text(`Customer ID: ${invoice.storeId}`, 10, yPos);

    // Date on the right side, aligned with the Invoice ID
    let xPosDate = 100; // X position for the date, positioned on the right
    // Store name on the same line as Customer ID
    pdf.text(`Store: ${invoice.storeName || "No Store Name"}`, xPosDate, yPos);

    yPos += lineHeight; // Move to next line for the address

    // Address below the store name
    let addressLines = pdf.splitTextToSize(
      `Address: ${invoice.storeAddress || "No Store Name"}` || "No Address",
      100
    );
    addressLines.forEach((line, index) => {
      pdf.text(line, xPosDate, yPos + index * (lineHeight * 0.6)); // Reduced line spacing between address lines
    });
    yPos += addressLines.length * (lineHeight * 0.6); // Adjusted yPos based on reduced line spacing

    // Correctly adjust yPos after setting the address
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos); // Draw a horizontal line before item details
    yPos += lineHeight / 2;

    // Set up column widths and positions
    let col1X = 10;
    let colWidth1 = 20; // Reduced width for the Image column
    let col2X = col1X + colWidth1 + 1;
    let colWidth2 = 25; // Reduced width for the Item Code column

    // Calculate the remaining width for the Item Details column
    let pageWidth = pdf.internal.pageSize.width - 15; // Adjusted page width
    let col3X = col2X + colWidth2 + 1;
    let colWidth3 = pageWidth - col3X - 15 - 15 - 15; // Reduced width for Item Details column

    let col4X = col3X + colWidth3 + 1;
    let colWidth4 = 15; // Increased Quantity column width
    let col5X = col4X + colWidth4 + 1;
    let colWidth5 = 15; // Increased Unit Price column width
    let col6X = col5X + colWidth5 + 1;
    let colWidth6 = colWidth5;

    let defaultFontSize = pdf.getFontSize();
    let smallerFontSize = defaultFontSize * 0.7; // Reduced font size
    let smallestFontSize = defaultFontSize * 0.6; // Reduced font size

    let currentPage = 1;
    let itemsPerPage = 9; // Decreased items per page
    let itemStartIndex = 0;
    let totalPriceAdded = false;

    while (itemStartIndex < invoice.items.length) {
      let itemsOnPage = invoice.items.slice(
        itemStartIndex,
        itemStartIndex + itemsPerPage
      );

      // Add column headers on each page
      pdf.setFontSize(smallerFontSize); // Set font size for column headers
      pdf.text("Image", col1X, yPos);
      pdf.text("Item Code", col2X, yPos);
      pdf.text("Item Details", col3X, yPos);
      pdf.setFontSize(smallestFontSize);
      pdf.text("Qty", col4X, yPos);
      pdf.text("Unit Price", col5X, yPos);
      pdf.text("Total", col6X + 3, yPos);
      yPos += lineHeight;

      itemsOnPage.forEach((item, index) => {
        let itemYPos = yPos + index * lineHeight * 3; // Increased line spacing for each item row

        // Draw the Image column
        pdf.rect(col1X, itemYPos, colWidth1, lineHeight * 3);
        if (item.imageUrl) {
          const imgFormat = item.imageUrl.split(";")[0].split("/")[1];
          pdf.addImage(
            item.imageUrl,
            imgFormat.toUpperCase(),
            col1X + 1,
            itemYPos + 1,
            colWidth1 - 2,
            lineHeight * 3 - 2 // Adjusted image size for increased line spacing
          );
        } else {
          pdf.text("No Image", col1X + 1, itemYPos + 1 + lineHeight);
        }

        // Draw the Item Code column
        pdf.rect(col2X, itemYPos, colWidth2, lineHeight * 3);
        let barcodeLines = pdf.splitTextToSize(
          item.barcode || "No ID",
          colWidth2 - 2
        );
        pdf.text(barcodeLines, col2X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Item Details column
        pdf.rect(col3X, itemYPos, colWidth3, lineHeight * 3);
        pdf.setFontSize(smallerFontSize);
        let splitDetails = pdf.splitTextToSize(
          `${item.name} ${item.description || ""} ${item.category || ""}`, // Optimized text formatting
          colWidth3 - 2
        );
        pdf.text(splitDetails, col3X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Quantity column
        pdf.setFontSize(smallestFontSize);
        pdf.rect(col4X, itemYPos, colWidth4, lineHeight * 3);
        pdf.text(
          item.quantity.toString(),
          col4X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Unit Price column
        pdf.rect(col5X, itemYPos, colWidth5, lineHeight * 3);
        pdf.text(
          `£${item.price.toFixed(2)}`,
          col5X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Total Price column
        pdf.rect(col6X, itemYPos, colWidth6, lineHeight * 3);
        pdf.text(
          `£${(item.quantity * item.price).toFixed(2)}`,
          col6X + 1,
          itemYPos + lineHeight * 2 // Adjusted vertical position
        );
      });

      // Update yPos for the end of the items on the current page
      yPos += itemsOnPage.length * lineHeight * 4 + lineHeight; // Adjusted line spacing

      // Check if there are more items to process
      if (itemStartIndex + itemsPerPage < invoice.items.length) {
        // Add a new page
        pdf.addPage();
        currentPage++;

        // Reset yPos for the new page
        yPos = 40;
      } else {
        // Reset font size to default for other texts
        pdf.setFontSize(defaultFontSize);

        // Check if there is enough space left on the current page to add the total price
        const remainingHeight = pdf.internal.pageSize.getHeight() - yPos;
        const totalPriceHeight = lineHeight * 2; // Adjust this value based on the desired height

        if (remainingHeight < totalPriceHeight) {
          // Not enough space on the current page, add a new page
          pdf.addPage();
          currentPage++;
          yPos = 40; // Reset yPos for the new page
        }

        // Draw the final horizontal line
        pdf.line(10, yPos, 200, yPos);
        yPos += lineHeight / 2;

        // Add Total Price on the right side
        const totalPriceString = `Invoice Total Price: £${invoice.totalPrice.toFixed(
          2
        )}`;
        const xTotalPrice =
          pdf.internal.pageSize.width -
          (pdf.getStringUnitWidth(totalPriceString) *
            pdf.internal.getFontSize()) /
            2 -
          20;
        pdf.text(totalPriceString, xTotalPrice, yPos);

        // Add page number and invoice ID (except for the first page)
        yPos += lineHeight;
        if (currentPage > 1) {
          pdf.text(
            `Page ${currentPage} - Invoice ID: ${invoice.id}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        } else {
          pdf.text(
            `Page ${currentPage}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        }

        totalPriceAdded = true;
      }

      itemStartIndex += itemsPerPage;
    }

    // Save the PDF
    pdf.save(`invoice-${invoice.id}.pdf`);
  };

  const downloadInvoiceBucketVAT = async (invoice: any) => {

    //NO VAT HERE
    const pdf = new jsPDF();

    // Add business icon

    // Set up some initial positions
    let yPos = 35; // Adjusted yPos for the left column
    const lineHeight = 8; // Reduced line height
    ///yPos += lineHeight * 1; // Increased gap after the phone number

    // Continue with the rest of your code

    // Correctly adjust yPos after setting the address
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos); // Draw a horizontal line before item details
    yPos += lineHeight / 2;

    // Set up column widths and positions
    let col1X = 10;
    let colWidth1 = 20; // Reduced width for the Image column
    let col2X = col1X + colWidth1 + 1;
    let colWidth2 = 25; // Reduced width for the Item Code column

    // Calculate the remaining width for the Item Details column
    let pageWidth = pdf.internal.pageSize.width - 15; // Adjusted page width
    let col3X = col2X + colWidth2 + 1;
    let colWidth3 = pageWidth - col3X - 15 - 15 - 15; // Reduced width for Item Details column

    let col4X = col3X + colWidth3 + 1;
    let colWidth4 = 15; // Increased Quantity column width
    let col5X = col4X + colWidth4 + 1;
    let colWidth5 = 15; // Increased Unit Price column width
    let col6X = col5X + colWidth5 + 1;
    let colWidth6 = colWidth5;

    let defaultFontSize = pdf.getFontSize();
    let smallerFontSize = defaultFontSize * 0.7; // Reduced font size
    let smallestFontSize = defaultFontSize * 0.6; // Reduced font size

    let currentPage = 1;
    let itemsPerPage = 9; // Decreased items per page
    let itemStartIndex = 0;
    let totalPriceAdded = false;

    let loadImages = []; // Initialize an empty array to store the promises

    while (itemStartIndex < invoice.items.length) {
      if (currentPage > 1) {
        itemsPerPage = 11; // Increase items per page after the first page
        yPos = 15; // Start items closer to the top for non-first pages
      }

      let itemsOnPage = invoice.items.slice(
        itemStartIndex,
        itemStartIndex + itemsPerPage
      );
      // Add column headers on each page

      // Add column headers on each page
      pdf.setFontSize(smallerFontSize); // Set font size for column headers
      pdf.text("Image", col1X, yPos);
      pdf.text("Item Code", col2X, yPos);
      pdf.text("Item Details", col3X, yPos);
      pdf.setFontSize(smallestFontSize);
      pdf.text("Qty", col4X, yPos);
      pdf.text("Unit Price", col5X, yPos);
      pdf.text("Total", col6X + 3, yPos);
      yPos += lineHeight;

      itemsOnPage.forEach(async (item, index) => {
        let itemYPos = yPos + index * lineHeight * 3; // Increased line spacing for each item row

        //console.log("Image URL:", item.imageUrl);

        // Draw the Image column
        pdf.rect(col1X, itemYPos, colWidth1, lineHeight * 3);
        if (item.imageUrl) {
          let currentPageWhenPromiseWasCreated = currentPage; // Capture the current page
          let imagePromise = fetch(item.imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              const imageUrl = urlCreator.createObjectURL(blob);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                  pdf.setPage(currentPageWhenPromiseWasCreated); // Set the page before drawing
                  pdf.addImage(
                    img,
                    col1X + 1,
                    itemYPos + 1,
                    colWidth1 - 2,
                    lineHeight * 3 - 2
                  );
                  resolve();
                };
                img.onerror = reject;
                img.src = imageUrl;
              });
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
          loadImages.push(imagePromise);
        } else {
          pdf.text("No Image", col1X + 1, itemYPos + 1 + lineHeight);
        }

        // Draw the Item Code column
        pdf.rect(col2X, itemYPos, colWidth2, lineHeight * 3);
        let barcodeLines = pdf.splitTextToSize(
          item.barcode || "No ID",
          colWidth2 - 2
        );
        pdf.text(barcodeLines, col2X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Item Details column
        pdf.rect(col3X, itemYPos, colWidth3, lineHeight * 3);
        pdf.setFontSize(smallerFontSize);
        let splitDetails = pdf.splitTextToSize(
          `${item.name} ${item.description || ""} ${item.category || ""}`, // Optimized text formatting
          colWidth3 - 2
        );
        pdf.text(splitDetails, col3X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Quantity column
        pdf.setFontSize(smallestFontSize);
        pdf.rect(col4X, itemYPos, colWidth4, lineHeight * 3);
        pdf.text(
          item.quantity.toString(),
          col4X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Unit Price column
        pdf.rect(col5X, itemYPos, colWidth5, lineHeight * 3);
        pdf.text(
          `£${item.unitPrice.toFixed(2)}`,
          col5X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Total Price column
        pdf.rect(col6X, itemYPos, colWidth6, lineHeight * 3);
        pdf.text(
          `£${(item.quantity * item.unitPrice).toFixed(2)}`,
          col6X + 1,
          itemYPos + lineHeight * 2 // Adjusted vertical position
        );
      });

      // Update yPos for the end of the items on the current page
      yPos += itemsOnPage.length * lineHeight * 4 + lineHeight; // Adjusted line spacing

      // Check if there are more items to process
      if (itemStartIndex + itemsPerPage < invoice.items.length) {
        // Add a new page
        pdf.addPage();
        currentPage++;

        // Reset yPos for the new page
        yPos = 40;
      } else {
        // Reset font size to default for other texts
        pdf.setFontSize(defaultFontSize);

        // Check if there is enough space left on the current page to add the total price
        const remainingHeight = pdf.internal.pageSize.getHeight() - yPos;
        const totalPriceHeight = lineHeight * 2; // Adjust this value based on the desired height

        if (remainingHeight < totalPriceHeight) {
          // Not enough space on the current page, add a new page
          pdf.addPage();
          currentPage++;
          yPos = 40; // Reset yPos for the new page
        }

        // Draw the final horizontal line
        pdf.line(10, yPos, 200, yPos);
        yPos += lineHeight / 1;

        // Add Total Price on the right side
        const totalPriceString = `Invoice Total Price: £${invoice.totalPrice.toFixed(
          2
        )}`;

        const xTotalPrice =
          pdf.internal.pageSize.width -
          (pdf.getStringUnitWidth(totalPriceString) *
            pdf.internal.getFontSize()) /
            2 -
          20;
        pdf.text(totalPriceString, xTotalPrice, yPos);

        // Add page number and invoice ID (except for the first page)
        yPos += lineHeight;
        if (currentPage > 1) {
          pdf.text(
            `Page ${currentPage} - Invoice ID: ${invoice.id}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        } else {
          pdf.text(
            `Page ${currentPage}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        }

        totalPriceAdded = true;
      }

      itemStartIndex += itemsPerPage;
    }

    Promise.all(loadImages)
      .then(() => {
        // Save the PDF
        pdf.save(`invoice-${invoice.id}.pdf`);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };

  const downloadInvoiceBucket = async (invoice: any) => {
    console.log('FOR VAT INVOICE')
    // change name with that above fix it once

    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15); // Reduced image size

    // Set up some initial positions
    let yPos = 35; // Adjusted yPos for the left column
    const lineHeight = 8; // Reduced line height

    // Invoice ID below the line
    pdf.text(`Invoice ID: ${invoice.id}`, 10, yPos);

    // Date below the Invoice ID in the left column
    const date = invoice.timestamp
      ? new Date(invoice.timestamp.seconds * 1000)
      : null;
    const dateString = date
      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      : "No timestamp";
    pdf.text(`Date: ${dateString}`, 10, (yPos += lineHeight)); // Move yPos down after writing

    // Customer ID below the date in the left column
    // Save the current font size
    const defaultFontSize = pdf.getFontSize();

    // Set the font size to half of the default for the Customer ID
    pdf.setFontSize(defaultFontSize / 1.5 );
    pdf.text(`Customer ID: ${invoice.storeId}`, 10, (yPos += lineHeight));


    pdf.text(`VAT Registration Number: 459421476`, 10, (yPos += lineHeight));

    // Revert to the default font size
    pdf.setFontSize(defaultFontSize);
    // Align the start yPos for the right column elements with the first element of left column
    let yPosRight = 35; // Start yPos for the right column aligned with 'Invoice ID'

    // Store name on the same yPos as 'Invoice ID'
    let xPosDate = 100; // X position for the right column elements
    pdf.text(
      `Store: ${invoice.storeName || "No Store Name"}`,
      xPosDate,
      yPosRight
    );

    // Address below the store name in the right column
    yPosRight += lineHeight; // Move yPos down after writing store name
    let addressLines = pdf.splitTextToSize(
      `Address: ${invoice.storeAddress || "No Address"}`,
      100
    );
    addressLines.forEach((line, index) => {
      pdf.text(line, xPosDate, yPosRight + index * lineHeight);
    });
    yPosRight += addressLines.length * lineHeight - lineHeight / 5; // Adjusting the gap before the phone number

    // Phone number below the address in the right column, with a reduced gap
    pdf.text(
      `Phone: ${invoice.phoneNumber || "No Phone Number"}`,
      xPosDate,
      yPosRight
    );

    ///yPos += lineHeight * 1; // Increased gap after the phone number

    // Continue with the rest of your code

    // Correctly adjust yPos after setting the address
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos); // Draw a horizontal line before item details
    yPos += lineHeight / 2;

    // Set up column widths and positions
    let col1X = 10;
    let colWidth1 = 20; // Reduced width for the Image column
    let col2X = col1X + colWidth1 + 1;
    let colWidth2 = 25; // Reduced width for the Item Code column

    // Calculate the remaining width for the Item Details column
    let pageWidth = pdf.internal.pageSize.width - 15; // Adjusted page width
    let col3X = col2X + colWidth2 + 1;
    let colWidth3 = pageWidth - col3X - 15 - 15 - 15; // Reduced width for Item Details column

    let col4X = col3X + colWidth3 + 1;
    let colWidth4 = 15; // Increased Quantity column width
    let col5X = col4X + colWidth4 + 1;
    let colWidth5 = 15; // Increased Unit Price column width
    let col6X = col5X + colWidth5 + 1;
    let colWidth6 = colWidth5;

    //let defaultFontSize = pdf.getFontSize();
    let smallerFontSize = defaultFontSize * 0.7; // Reduced font size
    let smallestFontSize = defaultFontSize * 0.6; // Reduced font size

    let currentPage = 1;
    let itemsPerPage = 9; // Decreased items per page
    let itemStartIndex = 0;
    let totalPriceAdded = false;

    let loadImages = []; // Initialize an empty array to store the promises

    while (itemStartIndex < invoice.items.length) {
      if (currentPage > 1) {
        itemsPerPage = 11; // Increase items per page after the first page
        yPos = 15; // Start items closer to the top for non-first pages
      }

      let itemsOnPage = invoice.items.slice(
        itemStartIndex,
        itemStartIndex + itemsPerPage
      );
      // Add column headers on each page

      // Add column headers on each page
      pdf.setFontSize(smallerFontSize); // Set font size for column headers
      pdf.text("Image", col1X, yPos);
      pdf.text("Item Code", col2X, yPos);
      pdf.text("Item Details", col3X, yPos);
      pdf.setFontSize(smallestFontSize);
      pdf.text("Qty", col4X, yPos);
      pdf.text("Unit Price", col5X, yPos);
      pdf.text("Total", col6X + 3, yPos);
      yPos += lineHeight;

      itemsOnPage.forEach(async (item, index) => {
        let itemYPos = yPos + index * lineHeight * 3; // Increased line spacing for each item row

        //console.log("Image URL:", item.imageUrl);

        // Draw the Image column
        pdf.rect(col1X, itemYPos, colWidth1, lineHeight * 3);
        if (item.imageUrl) {
          let currentPageWhenPromiseWasCreated = currentPage; // Capture the current page
          let imagePromise = fetch(item.imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              const imageUrl = urlCreator.createObjectURL(blob);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                  pdf.setPage(currentPageWhenPromiseWasCreated); // Set the page before drawing
                  pdf.addImage(
                    img,
                    col1X + 1,
                    itemYPos + 1,
                    colWidth1 - 2,
                    lineHeight * 3 - 2
                  );
                  resolve();
                };
                img.onerror = reject;
                img.src = imageUrl;
              });
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
          loadImages.push(imagePromise);
        } else {
          pdf.text("No Image", col1X + 1, itemYPos + 1 + lineHeight);
        }

        // Draw the Item Code column
        pdf.rect(col2X, itemYPos, colWidth2, lineHeight * 3);
        let barcodeLines = pdf.splitTextToSize(
          item.barcode || "No ID",
          colWidth2 - 2
        );
        pdf.text(barcodeLines, col2X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Item Details column
        pdf.rect(col3X, itemYPos, colWidth3, lineHeight * 3);
        pdf.setFontSize(smallerFontSize);
        let splitDetails = pdf.splitTextToSize(
          `${item.name} ${item.description || ""} ${item.category || ""}`, // Optimized text formatting
          colWidth3 - 2
        );
        pdf.text(splitDetails, col3X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Quantity column
        pdf.setFontSize(smallestFontSize);
        pdf.rect(col4X, itemYPos, colWidth4, lineHeight * 3);
        pdf.text(
          item.quantity.toString(),
          col4X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Unit Price column
        pdf.rect(col5X, itemYPos, colWidth5, lineHeight * 3);
        pdf.text(
          `£${item.unitPrice.toFixed(2)}`,
          col5X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Total Price column
        pdf.rect(col6X, itemYPos, colWidth6, lineHeight * 3);
        pdf.text(
          `£${(item.quantity * item.unitPrice).toFixed(2)}`,
          col6X + 1,
          itemYPos + lineHeight * 2 // Adjusted vertical position
        );
      });

      // Update yPos for the end of the items on the current page
      yPos += itemsOnPage.length * lineHeight * 4 + lineHeight; // Adjusted line spacing

      // Check if there are more items to process
      if (itemStartIndex + itemsPerPage < invoice.items.length) {
        // Add a new page
        pdf.addPage();
        currentPage++;

        // Reset yPos for the new page
        yPos = 40;
      } else {
        // Reset font size to default for other texts
        pdf.setFontSize(defaultFontSize);

        const totalPriceBeforeVat = invoice.totalPriceVat / 1.2;
        const vatAmount = invoice.totalPriceVat - totalPriceBeforeVat;
        
        // Check if there is enough space left on the current page to add the total price
        const remainingHeight = pdf.internal.pageSize.getHeight() - yPos;
        const totalPriceHeight = lineHeight * 3; // Adjust this value based on the desired height
        
        if (remainingHeight < totalPriceHeight) {
          // Not enough space on the current page, add a new page
          pdf.addPage();
          currentPage++;
          yPos = 40; // Reset yPos for the new page
        }
        
        // Draw the final horizontal line
        pdf.line(10, yPos, 200, yPos);
        yPos += lineHeight / 1;
        
        // Add Total Price before VAT on the right side
        const totalPriceBeforeVatString = `Total Goods: £${totalPriceBeforeVat.toFixed(2)}`;
        const xTotalPriceBeforeVat = pdf.internal.pageSize.width - (pdf.getStringUnitWidth(totalPriceBeforeVatString) * pdf.internal.getFontSize()) / 2 - 20;
        pdf.text(totalPriceBeforeVatString, xTotalPriceBeforeVat, yPos);
        
        // Add some space
        yPos += lineHeight;
        
        // Add VAT amount
        const vatAmountString = `VAT (20%): £${vatAmount.toFixed(2)}`;
        const xVatAmount = pdf.internal.pageSize.width - (pdf.getStringUnitWidth(vatAmountString) * pdf.internal.getFontSize()) / 2 - 20;
        pdf.text(vatAmountString, xVatAmount, yPos);
        
        // Add some space
        yPos += lineHeight;
        
        // Add Total Price on the right side
        const totalPriceString = `Total Goods with VAT: £${invoice.totalPriceVat.toFixed(2)}`;
        const xTotalPrice = pdf.internal.pageSize.width - (pdf.getStringUnitWidth(totalPriceString) * pdf.internal.getFontSize()) / 2 - 20;
        pdf.text(totalPriceString, xTotalPrice, yPos);

        // Add page number and invoice ID (except for the first page)
        yPos += lineHeight;
        if (currentPage > 1) {
          pdf.text(
            `Page ${currentPage} - Invoice ID: ${invoice.id}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        } else {
          pdf.text(
            `Page ${currentPage}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        }

        totalPriceAdded = true;
      }

      itemStartIndex += itemsPerPage;
    }

    Promise.all(loadImages)
      .then(() => {
        // Save the PDF
        pdf.save(`invoice-${invoice.id}.pdf`);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };

  const downloadInvoiceBucketOLD = async (invoice: any) => {
    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15); // Reduced image size

    // Set up some initial positions
    let yPos = 35; // Adjusted yPos for the left column
    const lineHeight = 8; // Reduced line height

    // Invoice ID below the line
    pdf.text(`Invoice ID: ${invoice.id}`, 10, yPos);

    // Date below the Invoice ID in the left column
    const date = invoice.timestamp
      ? new Date(invoice.timestamp.seconds * 1000)
      : null;
    const dateString = date
      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      : "No timestamp";
    pdf.text(`Date: ${dateString}`, 10, (yPos += lineHeight)); // Move yPos down after writing

    // Customer ID below the date in the left column
  // Save the current font size
  const defaultFontSize = pdf.getFontSize();

  // Set the font size to half of the default for the Customer ID
  pdf.setFontSize(defaultFontSize / 2);
  pdf.text(`Customer ID: ${invoice.storeId}`, 10, (yPos += lineHeight));

  // Revert to the default font size
  pdf.setFontSize(defaultFontSize);

  // Align the start yPos for the right column elements with the first element of left column
  let yPosRight = 35; // Start yPos for the right column aligned with 'Invoice ID'

  // Store name on the same yPos as 'Invoice ID'
  let xPosDate = 100; // X position for the right column elements
  pdf.text(
    `Store: ${invoice.storeName || "No Store Name"}`,
    xPosDate,
    yPosRight
  );

  // Address below the store name in the right column
  yPosRight += lineHeight; // Move yPos down after writing store name
  let addressLines = pdf.splitTextToSize(
    `Address: ${invoice.storeAddress || "No Address"}`,
    100
  );
    addressLines.forEach((line, index) => {
      pdf.text(line, xPosDate, yPosRight + index * lineHeight);
    });
    yPosRight += addressLines.length * lineHeight - lineHeight / 5; // Adjusting the gap before the phone number

    // Phone number below the address in the right column, with a reduced gap
    pdf.text(
      `Phone: ${invoice.phoneNumber || "No Phone Number"}`,
      xPosDate,
      yPosRight
    );

    ///yPos += lineHeight * 1; // Increased gap after the phone number

    // Continue with the rest of your code

    // Correctly adjust yPos after setting the address
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos); // Draw a horizontal line before item details
    yPos += lineHeight / 2;

    // Set up column widths and positions
    let col1X = 10;
    let colWidth1 = 20; // Reduced width for the Image column
    let col2X = col1X + colWidth1 + 1;
    let colWidth2 = 25; // Reduced width for the Item Code column

    // Calculate the remaining width for the Item Details column
    let pageWidth = pdf.internal.pageSize.width - 15; // Adjusted page width
    let col3X = col2X + colWidth2 + 1;
    let colWidth3 = pageWidth - col3X - 15 - 15 - 15; // Reduced width for Item Details column

    let col4X = col3X + colWidth3 + 1;
    let colWidth4 = 15; // Increased Quantity column width
    let col5X = col4X + colWidth4 + 1;
    let colWidth5 = 15; // Increased Unit Price column width
    let col6X = col5X + colWidth5 + 1;
    let colWidth6 = colWidth5;

    //let defaultFontSize = pdf.getFontSize();
    let smallerFontSize = defaultFontSize * 0.7; // Reduced font size
    let smallestFontSize = defaultFontSize * 0.6; // Reduced font size

    let currentPage = 1;
    let itemsPerPage = 9; // Decreased items per page
    let itemStartIndex = 0;
    let totalPriceAdded = false;

    let loadImages = []; // Initialize an empty array to store the promises

    while (itemStartIndex < invoice.items.length) {
      if (currentPage > 1) {
        itemsPerPage = 11; // Increase items per page after the first page
        yPos = 15; // Start items closer to the top for non-first pages
      }

      let itemsOnPage = invoice.items.slice(
        itemStartIndex,
        itemStartIndex + itemsPerPage
      );
      // Add column headers on each page

      // Add column headers on each page
      pdf.setFontSize(smallerFontSize); // Set font size for column headers
      pdf.text("Image", col1X, yPos);
      pdf.text("Item Code", col2X, yPos);
      pdf.text("Item Details", col3X, yPos);
      pdf.setFontSize(smallestFontSize);
      pdf.text("Qty", col4X, yPos);
      pdf.text("Unit Price", col5X, yPos);
      pdf.text("Total", col6X + 3, yPos);
      yPos += lineHeight;

      itemsOnPage.forEach(async (item, index) => {
        let itemYPos = yPos + index * lineHeight * 3; // Increased line spacing for each item row

        //console.log("Image URL:", item.imageUrl);

        // Draw the Image column
        pdf.rect(col1X, itemYPos, colWidth1, lineHeight * 3);
        if (item.imageUrl) {
          let currentPageWhenPromiseWasCreated = currentPage; // Capture the current page
          let imagePromise = fetch(item.imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              const imageUrl = urlCreator.createObjectURL(blob);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                  pdf.setPage(currentPageWhenPromiseWasCreated); // Set the page before drawing
                  pdf.addImage(
                    img,
                    col1X + 1,
                    itemYPos + 1,
                    colWidth1 - 2,
                    lineHeight * 3 - 2
                  );
                  resolve();
                };
                img.onerror = reject;
                img.src = imageUrl;
              });
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
          loadImages.push(imagePromise);
        } else {
          pdf.text("No Image", col1X + 1, itemYPos + 1 + lineHeight);
        }

        // Draw the Item Code column
        pdf.rect(col2X, itemYPos, colWidth2, lineHeight * 3);
        let barcodeLines = pdf.splitTextToSize(
          item.barcode || "No ID",
          colWidth2 - 2
        );
        pdf.text(barcodeLines, col2X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Item Details column
        pdf.rect(col3X, itemYPos, colWidth3, lineHeight * 3);
        pdf.setFontSize(smallerFontSize);
        let splitDetails = pdf.splitTextToSize(
          `${item.name} ${item.description || ""} ${item.category || ""}`, // Optimized text formatting
          colWidth3 - 2
        );
        pdf.text(splitDetails, col3X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Quantity column
        pdf.setFontSize(smallestFontSize);
        pdf.rect(col4X, itemYPos, colWidth4, lineHeight * 3);
        pdf.text(
          item.quantity.toString(),
          col4X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Unit Price column
        pdf.rect(col5X, itemYPos, colWidth5, lineHeight * 3);
        pdf.text(
          `£${item.unitPrice.toFixed(2)}`,
          col5X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Total Price column
        pdf.rect(col6X, itemYPos, colWidth6, lineHeight * 3);
        pdf.text(
          `£${(item.quantity * item.unitPrice).toFixed(2)}`,
          col6X + 1,
          itemYPos + lineHeight * 2 // Adjusted vertical position
        );
      });

      // Update yPos for the end of the items on the current page
      yPos += itemsOnPage.length * lineHeight * 4 + lineHeight; // Adjusted line spacing

      // Check if there are more items to process
      if (itemStartIndex + itemsPerPage < invoice.items.length) {
        // Add a new page
        pdf.addPage();
        currentPage++;

        // Reset yPos for the new page
        yPos = 40;
      } else {
        // Reset font size to default for other texts
        pdf.setFontSize(defaultFontSize);

        // Check if there is enough space left on the current page to add the total price
        const remainingHeight = pdf.internal.pageSize.getHeight() - yPos;
        const totalPriceHeight = lineHeight * 2; // Adjust this value based on the desired height

        if (remainingHeight < totalPriceHeight) {
          // Not enough space on the current page, add a new page
          pdf.addPage();
          currentPage++;
          yPos = 40; // Reset yPos for the new page
        }

        // Draw the final horizontal line
        pdf.line(10, yPos, 200, yPos);
        yPos += lineHeight / 1;

        // Add Total Price on the right side
        const totalPriceString = `Invoice Total Price: £${invoice.totalPrice.toFixed(
          2
        )}`;
        const xTotalPrice =
          pdf.internal.pageSize.width -
          (pdf.getStringUnitWidth(totalPriceString) *
            pdf.internal.getFontSize()) /
            2 -
          20;
        pdf.text(totalPriceString, xTotalPrice, yPos);

        // Add page number and invoice ID (except for the first page)
        yPos += lineHeight;
        if (currentPage > 1) {
          pdf.text(
            `Page ${currentPage} - Invoice ID: ${invoice.id}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        } else {
          pdf.text(
            `Page ${currentPage}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        }

        totalPriceAdded = true;
      }

      itemStartIndex += itemsPerPage;
    }

    Promise.all(loadImages)
      .then(() => {
        // Save the PDF
        pdf.save(`invoice-${invoice.id}.pdf`);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };

  const downloadInvoiceBucketOLDNOPHONE = async (invoice: any) => {
    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15); // Reduced image size

    // Set up some initial positions
    let yPos = 35; // Adjusted yPos for the left column
    const lineHeight = 8; // Reduced line height

    // Invoice ID below the line
    pdf.text(`Invoice ID: ${invoice.id}`, 10, yPos);

    // Date below the Invoice ID in the left column
    const date = invoice.timestamp
      ? new Date(invoice.timestamp.seconds * 1000)
      : null;
    const dateString = date
      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      : "No timestamp";
    pdf.text(`Date: ${dateString}`, 10, (yPos += lineHeight)); // Move yPos down after writing

    // Customer ID below the date in the left column
  // Save the current font size
  const defaultFontSize = pdf.getFontSize();

  // Set the font size to half of the default for the Customer ID
  pdf.setFontSize(defaultFontSize / 2);
  pdf.text(`Customer ID: ${invoice.storeId}`, 10, (yPos += lineHeight));

  // Revert to the default font size
  pdf.setFontSize(defaultFontSize);

  // Align the start yPos for the right column elements with the first element of left column
  let yPosRight = 35; // Start yPos for the right column aligned with 'Invoice ID'

  // Store name on the same yPos as 'Invoice ID'
  let xPosDate = 100; // X position for the right column elements
  pdf.text(
    `Store: ${invoice.storeName || "No Store Name"}`,
    xPosDate,
    yPosRight
  );

  // Address below the store name in the right column
  yPosRight += lineHeight; // Move yPos down after writing store name
  let addressLines = pdf.splitTextToSize(
    `Address: ${invoice.storeAddress || "No Address"}`,
    100
  );
    addressLines.forEach((line, index) => {
      pdf.text(line, xPosDate, yPosRight + index * lineHeight);
    });
    yPosRight += addressLines.length * lineHeight - lineHeight / 5; // Adjusting the gap before the phone number

    // Phone number below the address in the right column, with a reduced gap
    //pdf.text(
    //  `Phone: ${invoice.phoneNumber || "No Phone Number"}`,
    //  xPosDate,
    //  yPosRight
    //);

    ///yPos += lineHeight * 1; // Increased gap after the phone number

    // Continue with the rest of your code

    // Correctly adjust yPos after setting the address
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos); // Draw a horizontal line before item details
    yPos += lineHeight / 2;

    // Set up column widths and positions
    let col1X = 10;
    let colWidth1 = 20; // Reduced width for the Image column
    let col2X = col1X + colWidth1 + 1;
    let colWidth2 = 25; // Reduced width for the Item Code column

    // Calculate the remaining width for the Item Details column
    let pageWidth = pdf.internal.pageSize.width - 15; // Adjusted page width
    let col3X = col2X + colWidth2 + 1;
    let colWidth3 = pageWidth - col3X - 15 - 15 - 15; // Reduced width for Item Details column

    let col4X = col3X + colWidth3 + 1;
    let colWidth4 = 15; // Increased Quantity column width
    let col5X = col4X + colWidth4 + 1;
    let colWidth5 = 15; // Increased Unit Price column width
    let col6X = col5X + colWidth5 + 1;
    let colWidth6 = colWidth5;

    //let defaultFontSize = pdf.getFontSize();
    let smallerFontSize = defaultFontSize * 0.7; // Reduced font size
    let smallestFontSize = defaultFontSize * 0.6; // Reduced font size

    let currentPage = 1;
    let itemsPerPage = 9; // Decreased items per page
    let itemStartIndex = 0;
    let totalPriceAdded = false;

    let loadImages = []; // Initialize an empty array to store the promises

    while (itemStartIndex < invoice.items.length) {
      if (currentPage > 1) {
        itemsPerPage = 11; // Increase items per page after the first page
        yPos = 15; // Start items closer to the top for non-first pages
      }

      let itemsOnPage = invoice.items.slice(
        itemStartIndex,
        itemStartIndex + itemsPerPage
      );
      // Add column headers on each page

      // Add column headers on each page
      pdf.setFontSize(smallerFontSize); // Set font size for column headers
      pdf.text("Image", col1X, yPos);
      pdf.text("Item Code", col2X, yPos);
      pdf.text("Item Details", col3X, yPos);
      pdf.setFontSize(smallestFontSize);
      pdf.text("Qty", col4X, yPos);
      pdf.text("Unit Price", col5X, yPos);
      pdf.text("Total", col6X + 3, yPos);
      yPos += lineHeight;

      itemsOnPage.forEach(async (item, index) => {
        let itemYPos = yPos + index * lineHeight * 3; // Increased line spacing for each item row

        //console.log("Image URL:", item.imageUrl);

        // Draw the Image column
        pdf.rect(col1X, itemYPos, colWidth1, lineHeight * 3);
        if (item.imageUrl) {
          let currentPageWhenPromiseWasCreated = currentPage; // Capture the current page
          let imagePromise = fetch(item.imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const urlCreator = window.URL || window.webkitURL;
              const imageUrl = urlCreator.createObjectURL(blob);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                  pdf.setPage(currentPageWhenPromiseWasCreated); // Set the page before drawing
                  pdf.addImage(
                    img,
                    col1X + 1,
                    itemYPos + 1,
                    colWidth1 - 2,
                    lineHeight * 3 - 2
                  );
                  resolve();
                };
                img.onerror = reject;
                img.src = imageUrl;
              });
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
          loadImages.push(imagePromise);
        } else {
          pdf.text("No Image", col1X + 1, itemYPos + 1 + lineHeight);
        }

        // Draw the Item Code column
        pdf.rect(col2X, itemYPos, colWidth2, lineHeight * 3);
        let barcodeLines = pdf.splitTextToSize(
          item.barcode || "No ID",
          colWidth2 - 2
        );
        pdf.text(barcodeLines, col2X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Item Details column
        pdf.rect(col3X, itemYPos, colWidth3, lineHeight * 3);
        pdf.setFontSize(smallerFontSize);
        let splitDetails = pdf.splitTextToSize(
          `${item.name} ${item.description || ""} ${item.category || ""}`, // Optimized text formatting
          colWidth3 - 2
        );
        pdf.text(splitDetails, col3X + 1, itemYPos + lineHeight); // Adjusted vertical position

        // Draw the Quantity column
        pdf.setFontSize(smallestFontSize);
        pdf.rect(col4X, itemYPos, colWidth4, lineHeight * 3);
        pdf.text(
          item.quantity.toString(),
          col4X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Unit Price column
        pdf.rect(col5X, itemYPos, colWidth5, lineHeight * 3);
        pdf.text(
          `£${item.unitPrice.toFixed(2)}`,
          col5X + 1,
          itemYPos + lineHeight * 2
        ); // Adjusted vertical position

        // Draw the Total Price column
        pdf.rect(col6X, itemYPos, colWidth6, lineHeight * 3);
        pdf.text(
          `£${(item.quantity * item.unitPrice).toFixed(2)}`,
          col6X + 1,
          itemYPos + lineHeight * 2 // Adjusted vertical position
        );
      });

      // Update yPos for the end of the items on the current page
      yPos += itemsOnPage.length * lineHeight * 4 + lineHeight; // Adjusted line spacing

      // Check if there are more items to process
      if (itemStartIndex + itemsPerPage < invoice.items.length) {
        // Add a new page
        pdf.addPage();
        currentPage++;

        // Reset yPos for the new page
        yPos = 40;
      } else {
        // Reset font size to default for other texts
        pdf.setFontSize(defaultFontSize);

        // Check if there is enough space left on the current page to add the total price
        const remainingHeight = pdf.internal.pageSize.getHeight() - yPos;
        const totalPriceHeight = lineHeight * 2; // Adjust this value based on the desired height

        if (remainingHeight < totalPriceHeight) {
          // Not enough space on the current page, add a new page
          pdf.addPage();
          currentPage++;
          yPos = 40; // Reset yPos for the new page
        }

        // Draw the final horizontal line
        pdf.line(10, yPos, 200, yPos);
        yPos += lineHeight / 1;

        // Add Total Price on the right side
        const totalPriceString = `Invoice Total Price: £${invoice.totalPrice.toFixed(
          2
        )}`;
        const xTotalPrice =
          pdf.internal.pageSize.width -
          (pdf.getStringUnitWidth(totalPriceString) *
            pdf.internal.getFontSize()) /
            2 -
          20;
        pdf.text(totalPriceString, xTotalPrice, yPos);

        // Add page number and invoice ID (except for the first page)
        yPos += lineHeight;
        if (currentPage > 1) {
          pdf.text(
            `Page ${currentPage} - Invoice ID: ${invoice.id}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        } else {
          pdf.text(
            `Page ${currentPage}`,
            pdf.internal.pageSize.width / 2,
            yPos
          );
        }

        totalPriceAdded = true;
      }

      itemStartIndex += itemsPerPage;
    }

    Promise.all(loadImages)
      .then(() => {
        // Save the PDF
        pdf.save(`invoice-${invoice.id}.pdf`);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  };

    //only god himself knows how this works
    //swap vat and bucket and it gets fixed

  const downloadInvoiceAsPDF = (invoice: any) => {
    // Check if totalPriceVat exists
    if (invoice.totalPriceVat) {
      console.log("invoice with NO VAT items");

      // Create a new invoice object without VAT items
      const filteredInvoice = {
        ...invoice,
        items: invoice.items.filter((item: any) => !item.vat),
      };
      downloadInvoiceBucketVAT(filteredInvoice);
      return;
    }

    // Convert the given date/time string to a Date object
    const cutoffDate = new Date("2024-04-30T13:06:58").getTime() / 1000; // Convert to Unix time
    const newCutoffDate = new Date("2024-06-19T00:00:00").getTime() / 1000; // Convert to Unix time
    
    // Check the invoice date
    if (invoice.timestamp && invoice.timestamp.seconds < cutoffDate) {
      console.log("old");
      downloadInvoiceBase(invoice);
    } else if (invoice.timestamp && invoice.timestamp.seconds < newCutoffDate) {
      console.log("between");
      downloadInvoiceBucketOLD(invoice);
    } else {
      console.log("newest");
      downloadInvoiceBucketVAT(invoice);
    }
  };

  const downloadInvoiceBucketWithCustomId = async (invoice) => {
    const customId = promptForDateTime();
    if (!customId) return;
  
    const customDate = new Date(customId.replace(" ", "T"));
    const customTimestamp = {
      seconds: Math.floor(customDate.getTime() / 1000),
      nanoseconds: 0
    };
  
    const customInvoice = {
      ...invoice,
      id: customId,
      timestamp: customTimestamp
    };
  
    downloadInvoiceBucketOLDNOPHONE(customInvoice);
  };

  const promptForDateTime = () => {
    const dateTime = prompt("Enter the date and time for the invoice (YYYY-MM-DD HH:mm:ss):");
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    if (!dateTime || !dateTimeRegex.test(dateTime)) {
      alert("Invalid date-time format. Please enter in the format YYYY-MM-DD HH:mm:ss.");
      return null;
    }
    return dateTime;
  };

  const handleListViewToggle = () => {
    // Toggle between grid and list view
    setIsListView(!isListView);
  };

  const addButtonStyle: React.CSSProperties = {
    backgroundColor: "#1c345d",
    color: "white",
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const listContainerStyle: React.CSSProperties = {
    paddingLeft: 50,
    paddingRight: 50, // Added padding to the right for symmetry
    display: "grid", // Using grid layout
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Creating columns that adjust to the content size
    gap: "20px", // Space between grid items
    alignItems: "start", // Align items at the start of each cell
    maxHeight: "calc(57vh)", // Adjust based on your header/footer size
    overflowY: "auto", // Enable vertical scrolling
  };

  const invoiceItemStyle: React.CSSProperties = {
    border: "4px solid #ddd",
    padding: "20px",
    display: "flex",
    flexDirection: "column", // Stack content vertically
    justifyContent: "space-between", // Distribute space evenly
  };

  const invoiceListStyle: React.CSSProperties = {
    border: "4px solid #ddd",
    padding: "20px",
    display: "flex",
    flexDirection: "row", // Stack content vertically
    justifyContent: "space-between", // Distribute space evenly
  };

  const tabStyles: React.CSSProperties = {
    cursor: "pointer",
    padding: "10px 20px",
    margin: "0 5px",
    borderBottom: "3px solid transparent",
    fontSize: "16px",
  };

  const activeTabStyles: React.CSSProperties = {
    ...tabStyles,
    borderBottom: "3px solid #007bff",
    fontWeight: "bold",
  };

  const tabsContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    margin: "0px 0",
  };

  const dropdownStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
    padding: 10,
    marginLeft: 50,
    borderRadius: 10,
  };

  const labelStyle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: 17,
    //paddingTop: "15px",
    //paddingBottom: "5px",
    paddingTop: 30,
    paddingLeft: 50,
  };

  const filteredInvoices = invoices.filter((invoice) => {
    // Filter based on status
    let statusFilter = true;
    switch (activeTab) {
      case "Pending":
        statusFilter = !invoice.status || invoice.status === "Pending";
        break;
      case "Accepted":
        // Show only accepted and not archived invoices
        statusFilter = invoice.status === "Accepted" && !invoice.archived;
        break;
      case "Denied":
        // Show only denied and not archived invoices
        statusFilter = invoice.status === "Denied" && !invoice.archived;
        break;
      case "Archived":
        statusFilter = invoice.archived === true;
        break;
      default:
        statusFilter = true;
        break;
    }

    // Filter based on selected store
    let storeFilter = true;
    if (selectedStore) {
      const selectedStoreObj = prospects.find(
        (prospect) => prospect.id === selectedStore
      );
      if (selectedStoreObj) {
        storeFilter = invoice.storeName === selectedStoreObj.name;
      }
    }

    return statusFilter && storeFilter;
  });

  const renderInvoices = () => {
    if (isLoading) {
      return <p style={labelStyle}>Loading...</p>;
    } else if (filteredInvoices.length > 0) {
      return isListView ? (
        <div
          style={
            isListView
              ? { ...listContainerStyle, gridTemplateColumns: "1fr" }
              : listContainerStyle
          }
        >
          {filteredInvoices.map((invoice) => (
            <div key={invoice.id} style={invoiceListStyle}>
              <h3>Invoice ID: {invoice.id}</h3>
              <p>
                Date/Time:{" "}
                {invoice.timestamp
                  ? new Date(invoice.timestamp.seconds * 1000).toLocaleString()
                  : "No timestamp"}
              </p>
              <p>Store: {invoice.storeName || "No Store Name"}</p>
              <p>Address: {invoice.storeAddress || "No Address"}</p>

              {/*invoice.items.map((item, index) => (
                <p key={index}>{`Item: ${item.name}, Quantity: ${
                  item.quantity
                }, Price: £${item.price.toFixed(2)}`}</p>
              ))*/}

              <p>Total Price: £{invoice.totalPrice.toFixed(2)}</p>
              {invoice.totalPriceVat && (
                <p>Total Price VAT: £{invoice.totalPriceVat.toFixed(2)}</p>
              )}
              {invoice.status === "Accepted" ? (
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    alignSelf: "center",
                    backgroundColor: "green",
                    fontSize: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                  }}
                >
                  Accepted
                </p>
              ) : invoice.status === "Denied" ? (
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    alignSelf: "center",
                    backgroundColor: "red",
                    fontSize: 15,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                  }}
                >
                  Denied
                </p>
              ) : (
                <>
                  <button
                    style={{ ...addButtonStyle, backgroundColor: "#28a745" }}
                    onClick={() => acceptInvoice(invoice)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Processing..." : "Accept"}
                  </button>
                  <button
                    style={{ ...addButtonStyle, backgroundColor: "#dc3545" }}
                    onClick={() => denyInvoice(invoice)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Processing..." : "Deny"}
                  </button>
                </>
              )}
              {invoice.status === "Accepted" && (
              <button
              style={addButtonStyle}
              onClick={() => downloadInvoiceAsPDF(invoice)}
            >
              Download
            </button>
              )}

              {invoice.status === "Accepted" && invoice.totalPriceVat && (
                <button
                  style={addButtonStyle}
                  onClick={() => {
                    const vatOnlyInvoice = {
                      ...invoice,
                      items: invoice.items.filter((item: any) => item.vat), // Filter for VAT items only
                    };
                    downloadInvoiceBucket(vatOnlyInvoice);
                  }}
                >
                  Download VAT
                </button>
              )}
              

              <button
                style={{ ...addButtonStyle, backgroundColor: "#007bff" }}
                onClick={() => openDetailsModal(invoice)}
              >
                Invoice Details
              </button>

              <button
                style={{ ...addButtonStyle, backgroundColor: "#e63946" }}
                onClick={() => handleArchiveInvoice(invoice)}
                disabled={isProcessing}
              >
                {isProcessing ? "Processing..." : "Archive Invoice"}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div style={listContainerStyle}>
          {filteredInvoices.map((invoice) => (
            <div key={invoice.id} style={invoiceItemStyle}>
              <h3>Invoice ID: {invoice.id}</h3>
              <p>
                Date/Time:{" "}
                {invoice.timestamp
                  ? new Date(invoice.timestamp.seconds * 1000).toLocaleString()
                  : "No timestamp"}
              </p>
              <p>Store: {invoice.storeName || "No Store Name"}</p>
              <p>Address: {invoice.storeAddress || "No Address"}</p>
              {/*invoice.items.map((item, index) => (
                <p key={index}>{`Item: ${item.name}, Quantity: ${
                  item.quantity
                }, Price: £${item.price.toFixed(2)}`}</p>
              ))*/}
              <p>Total Price: £{invoice.totalPrice.toFixed(2)}</p>

              {invoice.totalPriceVat && (
                <p>Total Price VAT: £{invoice.totalPriceVat.toFixed(2)}</p>
              )}

              {invoice.status === "Accepted" ? (
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    alignSelf: "center",
                    backgroundColor: "green",
                    fontSize: 25,
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                  }}
                >
                  Accepted
                </p>
              ) : invoice.status === "Denied" ? (
                <p
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    alignSelf: "center",
                    backgroundColor: "red",
                    fontSize: 25,
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 20,
                  }}
                >
                  Denied
                </p>
              ) : (
                <>
                  <button
                    style={{ ...addButtonStyle, backgroundColor: "#28a745" }}
                    onClick={() => acceptInvoice(invoice)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Processing..." : "Accept"}
                  </button>
                  <button
                    style={{ ...addButtonStyle, backgroundColor: "#dc3545" }}
                    onClick={() => denyInvoice(invoice)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Processing..." : "Deny"}
                  </button>
                </>
              )}

              {invoice.status === "Accepted" && (
             <button
             style={addButtonStyle}
             onClick={() => downloadInvoiceAsPDF(invoice)}
           >
             Download
           </button>
              )}

              {invoice.status === "Accepted" && invoice.totalPriceVat && (
                <button
                  style={addButtonStyle}
                  onClick={() => {
                    const vatOnlyInvoice = {
                      ...invoice,
                      items: invoice.items.filter((item: any) => item.vat), // Filter for VAT items only
                    };
                    downloadInvoiceBucket(vatOnlyInvoice);
                  }}
                >
                  Download VAT
                </button>
              )}

              <button
                style={{ ...addButtonStyle, backgroundColor: "#007bff" }}
                onClick={() => openDetailsModal(invoice)}
              >
                Invoice Details
              </button>

              {!invoice.archived && (
                <button
                  style={{ ...addButtonStyle, backgroundColor: "#e63946" }}
                  onClick={() => handleArchiveInvoice(invoice)}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Processing..." : "Archive Invoice"}
                </button>
              )}
              {invoice.archived && (
                <button
                  style={{ ...addButtonStyle, backgroundColor: "#e63946" }}
                  onClick={() => handleVoidInvoice(invoice)}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Processing..." : "Void"}
                </button>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      // This block will execute if filteredInvoices.length is 0
      return <p style={labelStyle}>No invoices.</p>;
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div>
          <div style={labelStyle}>Filter By Store:</div>
          <select
            style={dropdownStyle}
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
          >
            <option value="">All Stores</option>
            {prospects.map((prospect) => (
              <option key={prospect.id} value={prospect.id}>
                {prospect.name}
              </option>
            ))}
          </select>
        </div>
        <label>
          <input
            type="checkbox"
            style={{ marginLeft: 50 }}
            checked={isListView}
            onChange={handleListViewToggle}
          />
          List View
        </label>
      </div>

      <div style={tabsContainerStyle}>
        {["Pending", "Accepted", "Denied", "Archived"].map((tab) => (
          <div
            key={tab}
            style={activeTab === tab ? activeTabStyles : tabStyles}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
          marginLeft: 40,
        }}
      >
        <h1>Invoices</h1>
        {/*
        <button style={addButtonStyle} onClick={() => setIsAdding(true)}>
          Add Invoice
      </button>*/}
      </div>

      {renderInvoices()}

      {isDetailsModalOpen && selectedInvoice && (
        <InvoiceDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
};

export default Invoices;
