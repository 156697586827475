import { collection, onSnapshot } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../App";

interface OrdersFilterProps {
  onFilterChange: (filters: Filters) => void;
}

interface Filters {
  barcode: string;
  name: string;
  category: string;
  showArchived: boolean;
}

const OrdersFilter: React.FC<OrdersFilterProps> = ({ onFilterChange }) => {
  const [filters, setFilters] = useState<Filters>({
    barcode: "",
    name: "",
    category: "",
    showArchived: false,
  });

  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Categories"), (snapshot) => {
      const fetchedCategories = snapshot.docs.map((doc) => doc.data().Category);
      setCategories(fetchedCategories);
    });
    return () => unsubscribe();
  }, []);

  // Update the filters and notify the parent component
  const updateFilters = (newFilters: Partial<Filters>) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      onFilterChange(updatedFilters);
      return updatedFilters;
    });
  };

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ barcode: event.target.value });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ name: event.target.value });
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateFilters({ category: event.target.value });
  };

  const handleShowArchivedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ showArchived: event.target.checked });
  };
  

  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "10px",
    paddingTop: "10px",
    paddingLeft: "5%",
    paddingRight: "5%",
    marginBottom: "20px",
  };

  const inputContainerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "22%",
  };

  const inputStyle: React.CSSProperties = {
    margin: "5px 0",
    padding: "10px",
    width: "100%",
    height: "35px",
    borderRadius: "12px",
    borderWidth: "1px",
  };

  const labelStyle: React.CSSProperties = {
    marginBottom: "5px",
    fontWeight: 600,
    fontSize: 15,
  };

  const selectStyle: React.CSSProperties = {
    ...inputStyle,
    height: "57px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <div style={inputContainerStyle}>
        <div style={labelStyle}>Barcode Filter:</div>
        <input
          style={inputStyle}
          placeholder="Enter Barcode"
          value={filters.barcode}
          onChange={handleBarcodeChange}
        />
      </div>
      <div style={inputContainerStyle}>
        <div style={labelStyle}>Name Filter:</div>
        <input
          style={inputStyle}
          placeholder="Enter Name"
          value={filters.name}
          onChange={handleNameChange}
        />
      </div>
      <div style={inputContainerStyle}>
        <div style={labelStyle}>Category Filter:</div>
        <select
          style={selectStyle}
          value={filters.category}
          onChange={handleCategoryChange}
        >
          <option value="">Select a category</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div style={inputContainerStyle}>
  <div style={labelStyle}>Show Archived:</div>
  <input
    type="checkbox"
    checked={filters.showArchived}
    onChange={handleShowArchivedChange}
  />
</div>

      {/*
      <div
        style={{
          ...inputContainerStyle,
          width: "5%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={labelStyle}>Show Archived:</div>
        <input
    type="checkbox"
    checked={filters.showArchived} // Sync with filters
    onChange={handleShowArchivedChange} // Handle change
  />
      </div>
      */}
    </div>
  );
};

export default OrdersFilter;
