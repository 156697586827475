//@ts-nocheck
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { db } from "../App";
import TitleBar from "./TitleBar";

import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { formatEpochDate, formatEpochTime } from "./Functions";
import AddProspectModal from "./AddProspectModal";
import ProspectDetailsModal from "./ProspectDetailsModal";

const Prospects: React.FC = () => {
  const [prospects, setProspects] = useState([]);
  const [isAdding, setIsAdding] = useState(false); // To control the form/modal visibility
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false); // To toggle between active and archived

  useEffect(() => {
    setIsLoading(true); // Set loading to true when component mounts
    const unsubscribe = onSnapshot(collection(db, "Prospects"), (snapshot) => {
      const prospectsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProspects(prospectsList);
      setIsLoading(false); // Set loading to false after prospects are fetched
    });

    return () => unsubscribe();
  }, []);

  const openDetailsModal = (prospect: any) => {
    setSelectedProspect(prospect);
    setIsDetailsModalOpen(true);
  };

  const gridContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))", // This will create a responsive grid layout
    gap: "60px 20px", // Space between grid items, "vertical horizontal"
    padding: "20px", // Padding around the entire grid
  };
  const prospectCardStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Space out the content inside the card
    cursor: "pointer", // Indicate that the items are clickable
    height: "100%", // Make sure all cards are of equal height
  };

  const addButtonStyle: React.CSSProperties = {
    backgroundColor: "#1c345d", // Example green color
    color: "white",
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const scrollableContainerStyle: React.CSSProperties = {
    height: "75vh", // Adjust the height as needed
    overflowY: "auto", // Enable vertical scrolling
    padding: "0 20px", // Optional: padding for better aesthetics
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
          marginLeft: 40,
        }}
      >
        <h1>Prospects</h1>
        <div>
          <button style={addButtonStyle} onClick={() => setIsAdding(true)}>
            Add Prospect
          </button>
          <button
            style={addButtonStyle}
            onClick={() => setShowArchived((prev) => !prev)}
          >
            {showArchived ? "Show Active" : "Show Archived"}
          </button>
        </div>
      </div>
      <div style={scrollableContainerStyle}>
        <div style={gridContainerStyle}>
          {isLoading ? (
            <p>Loading...</p>
          ) : prospects.length > 0 ? (
            prospects
              .filter((prospect) =>
                showArchived
                  ? prospect.archived === true
                  : prospect.archived === false || prospect.archived === undefined
              )
              .map((prospect) => (
                <div
                  key={prospect.id}
                  style={prospectCardStyle}
                  onClick={() => openDetailsModal(prospect)}
                >
                  <h3>Store: {prospect.name}</h3>
                  <p>Address: {prospect.address}</p>
                  <p>Email: {prospect.email}</p>
                  <p>Number: {prospect.phoneNumber}</p>
                  <button
                    style={{ ...addButtonStyle, marginTop: "auto" }} // Auto margin to push the button to the bottom
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the onClick of the parent from firing
                      openDetailsModal(prospect);
                    }}
                  >
                    Details
                  </button>
                </div>
              ))
          ) : (
            <p>No prospects found.</p>
          )}
        </div>
      </div>

      {isAdding && (
        <AddProspectModal
          isOpen={isAdding}
          onClose={() => setIsAdding(false)}
        />
      )}
      {isDetailsModalOpen && selectedProspect && (
        <ProspectDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          prospect={selectedProspect}
        />
      )}
    </div>
  );
};

export default Prospects;
