//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import { useLanguage } from "../LanguageContext";
import { db } from "../App";
import AddWarehouseModal from "./AddWarehouseModal";
import TitleBar from "./TitleBar";
import OrdersFilter from "./OrdersFilter";
import RowHeader from "./RowHeader";
import WarehouseRow from "./WarehouseRow";
import {
  collection,
  getDocs,
  onSnapshot,
  updateDoc,
  doc,
  query,
  orderBy,
  startAfter,
  limit,
  where,
} from "firebase/firestore";
import { formatEpochDate, formatEpochTime } from "./Functions";
import ItemDetailsModal from "./ItemDetailsModal";
import CategoryModal from "./CategoryModal";
import axios from "axios";
import { SERVER_URL } from "./Types";
import ParsePDFModal from "./ParsePDFModal"; // Import the ParsePDFModal component
import CategoryDeleteModal from "./CategoryDeleteModal";

const Warehouse: React.FC = () => {
  const { language } = useLanguage();

  const translations = {
    Warehouse: language === "kurdish" ? "KURDISH FOR WAREHOUSE" : "Warehouse",
    Categories: language === "kurdish" ? "+ نوێ" : "Categories +",
    CategoriesDelete: language === "kurdish" ? "+ نوێ" : "Delete Category",
    PDFUpload: language === "kurdish" ? "+ نوێ" : "Upload PDF",
    New: language === "kurdish" ? "+ نوێ" : "NEW +",
    Title: language === "kurdish" ? ":ناونیشان" : "Title:",
    Body: language === "kurdish" ? ":ناوەڕۆک" : "Body:",
    Recipients: language === "kurdish" ? ":وەرگرەکان" : "Recipients:",
    Date: language === "kurdish" ? ":بەروار" : "Date:",
  };

  const [file, setFile] = useState<File | null>(null);
  const [uploadResponse, setUploadResponse] = useState({
    images: [],
    text: "",
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileModalOpen, setFileModalOpen] = useState(false); // State to control ParsePDFModal visibility

  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const [lastVisibleEntry, setLastVisibleEntry] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [filters, setFilters] = useState({
    barcode: "",
    name: "",
    category: "",
    showArchived: false,
  });


  const fetchEntries = async (isInitialLoad = false) => {
    try {
      let currentQuery = collection(db, "Warehouse");
  
      // Apply category, barcode, and name filters if they exist
      if (filters.category.trim() !== "") {
        currentQuery = query(
          currentQuery,
          where("category", "==", filters.category.trim())
        );
      }
  
      if (filters.barcode.trim() !== "") {
        currentQuery = query(
          currentQuery,
          where("barcode", "==", filters.barcode.trim())
        );
      }
  
      if (filters.name.trim() !== "") {
        currentQuery = query(
          currentQuery,
          where("name", "==", filters.name.trim())
        );
      }
  
      // Handle Archived and Non-Archived Separately
      if (filters.showArchived) {
        // Archived entries
        currentQuery = query(
          currentQuery,
          where("archived", "==", true),
          orderBy("timestamp", "desc"),
          limit(20)
        );
      } else {
        // Non-Archived entries, including missing 'archived' field
        currentQuery = query(
          currentQuery,
          orderBy("timestamp", "desc"),
          limit(20)
        );
      }
  
      const querySnapshot = await getDocs(currentQuery);
      const entries = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          archived: data.archived ?? false, // Treat missing 'archived' as false
        };
      });
  
      // Filter Non-Archived Entries (Including Missing Field)
      const filteredEntries = filters.showArchived
        ? entries
        : entries.filter(entry => !entry.archived || entry.archived === null);
  
      if (isInitialLoad) {
        setWarehouseEntries(filteredEntries);
      } else {
        setWarehouseEntries((prevEntries) => [...prevEntries, ...filteredEntries]);
      }
  
      setLastVisibleEntry(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      setIsLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      console.error("Error fetching entries: ", error);
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };
  
  
  
  useEffect(() => {
    fetchEntries(true);
  }, [filters]);
  
  const loadMoreEntries = async () => {
    if (isLoadingMore || !lastVisibleEntry) return;
  
    setIsLoadingMore(true);
  
    try {
      let nextQuery = query(
        collection(db, "Warehouse"),
        orderBy("timestamp", "desc"),
        startAfter(lastVisibleEntry),
        limit(20)
      );
  
      if (filters.showArchived) {
        nextQuery = query(nextQuery, where("archived", "==", true));
      }
  
      const querySnapshot = await getDocs(nextQuery);
      const newEntries = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          archived: data.archived ?? false, // Treat missing 'archived' as false
        };
      });
  
      // Filter Non-Archived Entries if Needed
      const filteredEntries = filters.showArchived
        ? newEntries
        : newEntries.filter(entry => !entry.archived || entry.archived === null);
  
      setWarehouseEntries((prevEntries) => [...prevEntries, ...filteredEntries]);
  
      setLastVisibleEntry(querySnapshot.docs[querySnapshot.docs.length - 1] || null);
      setIsLoadingMore(false);
    } catch (error) {
      console.error("Error fetching more entries:", error);
      setIsLoadingMore(false);
    }
  };
  
  
  
  
  

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    //console.log(`ScrollTop: ${scrollTop}, ClientHeight: ${clientHeight}, ScrollHeight: ${scrollHeight}`); // Log the current scroll positions

    // Check if reached bottom
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      // Consider a small threshold to ensure it triggers
      console.log("Reached the bottom of the list");
      loadMoreEntries();
    }
  };

  const handlePDFUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleModalClose = () => {
    setFileModalOpen(false);
    setFile(null); // Reset the file state
    setUploadResponse({ images: [], text: "" }); // Reset the upload response
    setResponseStatus(""); // Reset the status message
    setResponseMessage(""); // Reset the response message
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const submitFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append("pdf", file);

      axios
        .post(`${SERVER_URL}/parse-pdf`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            // Parse the JSON string into an array of objects
            let textDataArray;
            try {
              textDataArray = JSON.parse(response.data.parsedText);
            } catch (error) {
              console.error("Error parsing text data:", error);
              setResponseStatus("error");
              setResponseMessage("Failed to parse text data.");
              return;
            }

            // Now map your images to this array
            const parsedData = response.data.images.map((image, index) => ({
              image: `data:image/jpeg;base64,${image.data}`,
              json: textDataArray[index], // Now this is a JSON object
            }));

            setUploadResponse({ parsedData });
            setResponseStatus("success");
            setResponseMessage("File processed successfully.");
            setFileModalOpen(true);
          } else {
            setResponseStatus("error");
            setResponseMessage("Unexpected response from the server.");
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          let errorMessage = "Error processing the file.";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }
          setResponseStatus("error");
          setResponseMessage(errorMessage);
        });
    }
  };
  
  
  
  

  useEffect(() => {
    if (file) {
      submitFile();
    }
  }, [file]);

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  const handleOpenCategoryModal = () => setIsCategoryModalOpen(true);
  const handleCloseCategoryModal = () => setIsCategoryModalOpen(false);

  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] =
    useState(false);

  const openDeleteCategoryModal = () => setIsDeleteCategoryModalOpen(true);
  const closeDeleteCategoryModal = () => setIsDeleteCategoryModalOpen(false);

  const [warehouseEntries, setWarehouseEntries] = useState<[]>([]); // State for storing fetched users
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedWarehouseEntry, setSelectedWarehouseEntry] = useState<
    string | null
  >(null); // Track the selected UID

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedItemForDetails, setSelectedItemForDetails] =
    useState<any>(null); // State to hold selected item for details

  const openAddModal = (item: any) => {
    setIsAddModalOpen(true);
  };
  const openDetailsModal = (item: any) => {
    console.log("Opening details modal for item:", item);

    setSelectedItemForDetails(item);
    setIsDetailsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsAddModalOpen(false);
  };

  /*
  console.log(`Querying for name: 'Birthday banner'`);
  const testQuery = query(
    collection(db, "Warehouse"),
    where("name", "==", "Birthday banner ")
  );
  getDocs(testQuery)
  .then(snapshot => {
    console.log("Number of entries fetched: ", snapshot.size);
    snapshot.forEach(doc => console.log(doc.id, " => ", doc.data()));
  })
  .catch(error => console.error("Error querying documents: ", error));
  */
 

 
  
  
  

  const formatEntryData = (doc: any) => {
    const data = doc.data();
    let formattedDate = "";
    let formattedDateTime = "";
    let timestamp = data.timestamp
      ? data.timestamp.toDate
        ? data.timestamp.toDate()
        : new Date(data.timestamp)
      : new Date(); // This ensures compatibility with both Timestamp objects and raw data
    formattedDate = formatEpochDate(timestamp.getTime());
    formattedDateTime = formatEpochTime(timestamp.getTime());

    return {
      ...data,
      id: doc.id, // Get the document ID from the document reference
      formattedDate,
      formattedDateTime,
    };
  };

  const headerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: language === "kurdish" ? "space-between" : "space-between",
    alignItems: "center",
    margin: "10px 60px 10px 60px",
    flexDirection: language === "kurdish" ? "row-reverse" : "row", // Adjust direction for Kurdish
  };

  const button: React.CSSProperties = {
    fontSize: "16px",
    color: "white", // Text color
    fontWeight: "600",
    backgroundColor: "#1c345d", // Background color
    borderRadius: "5px", // Make it round
    padding: "15px 40px", // Padding for sizing (adjust as needed)
    border: "none", // Remove default button border
    cursor: "pointer", // Change cursor to pointer on hover
    outline: "none", // Remove focus outline
  };

  const columnStyles: React.CSSProperties = {
    flex: 0.1, // Distributes space evenly
    paddingLeft: 40,
    //minWidth: '0', // Ensures flex shrinkage if necessary
  };
  const parentContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Stack children vertically
    height: "100vh", // Set the height of this container to the full viewport height
    // Subtract the height of any other elements like the header and any margins they have
  };
  const scrollableListContainerStyles: React.CSSProperties = {
    maxHeight: "62vh", // Adjust the height as necessary
    overflowY: "auto", // Enable vertical scrolling
    //backgroundColor:'pink'
  };

  const titles = {
    image: "",
    barcode: "#",
    name: "NAME",
    category: "CATEGORY",
    description: "DESCRIPTION",
    quantity: "QUANTITY",
    boxQuantity: "BOX QUANTITY",
    unitPrice: "UNIT PRICE",
    details: "DETAILS",
  };

  const percentages = {
    image: "10%",
    barcode: "12%",
    name: "11%",
    category: "11%",
    description: "12%",
    quantity: "11%",
    boxQuantity: "11%",
    unitPrice: "11%",
    details: "11%",
  };

  const handleFilterChange = (updatedFilters: any) => {
    setFilters(updatedFilters);
  };
  /*
  const updateBarcodesWithDocId = async () => {
    const querySnapshot = await getDocs(collection(db, "Warehouse"));
    querySnapshot.forEach(async (document) => {
      const documentRef = doc(db, "Warehouse", document.id);
      try {
        await updateDoc(documentRef, {
          barcode: document.id,
        });
        console.log(`Updated ${document.id} successfully`);
      } catch (error) {
        console.error(`Error updating document ${document.id}: `, error);
      }
    });
  };
  
  
  useEffect(() => {
    let isMounted = true;
  
    const updateBarcodes = async () => {
      try {
        //await updateBarcodesWithDocId();
        if (isMounted) {
          console.log("Barcodes updated");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error updating barcodes: ", error);
        }
      }
    };
  
    updateBarcodes();
  
    return () => {
      isMounted = false;
    };
  }, []);
  */

  /*
  const filteredEntries = warehouseEntries.filter((entry) => {
    return (
      (filters.barcode ? entry.barcode.includes(filters.barcode) : true) &&
      (filters.name
        ? entry.name.toLowerCase().includes(filters.name.toLowerCase())
        : true) &&
      (filters.category ? entry.category === filters.category : true) &&
      (filters.showArchived ? entry.archived : !entry.archived)
    );
  });
*/

  return (
    <div style={parentContainerStyles}>
      <div style={headerStyles}>
        <h1 style={{ fontSize: 35 }}>{translations.Warehouse}</h1>
        <div>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".pdf, .xlsx, .xls, .zip" // Allow PDF, Excel, and ZIP files
            />
          <button style={button} onClick={handlePDFUpload}>
            {translations.PDFUpload}
          </button>
          <button
            style={{ ...button, marginLeft: "60px" }}
            onClick={handleOpenCategoryModal}
          >
            {translations.Categories}
          </button>
          <button
            style={{ ...button, marginLeft: "60px" }}
            onClick={openDeleteCategoryModal}
          >
            {translations.CategoriesDelete}
          </button>
          <button
            style={{ ...button, marginLeft: "60px" }}
            onClick={openAddModal}
          >
            {translations.New}
          </button>
        </div>
      </div>
      {responseStatus && (
        <div
          style={{
            color: responseStatus === "error" ? "red" : "green",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          {responseMessage}
        </div>
      )}

      {/*
      <TitleBar title="Orders" />
  */}

      <OrdersFilter onFilterChange={handleFilterChange} />

      <RowHeader titles={titles} percentages={percentages} />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>Loading...</p>
        </div>
      ) : warehouseEntries.length > 0 ? (
        <div style={scrollableListContainerStyles} onScroll={handleScroll}>
          {warehouseEntries.map((entry: any, index: any) => (
            <WarehouseRow
              key={index}
              entry={{
                ...entry,
                //quantity: entry.quantity - (entry.reserved || 0), // Subtract reserved from the quantity
              }}
              openDetailsModal={openDetailsModal} // Passing openDetailsModal as a prop
            />
          ))}
          {isLoadingMore && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
                //height: "100%", // Adjust the height as needed or use a specific value
                //width: "100%", // Ensures the spinner is centered in the full container width
              }}
            >
              <div
                className="spinner-large"
                style={{
                  border: "8px solid #f3f3f3", // Light grey, represents the background
                  borderTop: "8px solid #3498db", // Blue, represents the spinning part
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  animation: "spin 2s linear infinite",
                }}
              ></div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <p style={{ fontSize: "24px", marginTop: -500 }}>No Entries...</p>
        </div>
      )}
      {isDetailsModalOpen && selectedItemForDetails && (
        <ItemDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          item={selectedItemForDetails} // Use selectedItemForDetails here
        />
      )}
      {isAddModalOpen && (
        <AddWarehouseModal isOpen={isAddModalOpen} onClose={onCloseModal} />
      )}
      {isCategoryModalOpen && (
        <CategoryModal
          isOpen={isCategoryModalOpen}
          onClose={handleCloseCategoryModal}
        />
      )}
      <ParsePDFModal
        isOpen={fileModalOpen}
        onClose={handleModalClose} // Use handleModalClose here
        //@ts-ignore
        parsedData={uploadResponse.parsedData} // Here you should pass only the array
      />
      <CategoryDeleteModal
        isOpen={isDeleteCategoryModalOpen}
        onClose={closeDeleteCategoryModal}
      />
    </div>
  );
};

export default Warehouse;
