//@ts-nocheck

import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../App"; // Ensure the correct import for your Firebase setup
import GenerateReportModal from "./GenerateReportModal";
import jsPDF from "jspdf";
import bIcon from "../star.jpeg";

const Reports: React.FC = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [selectedReport, setSelectedReport] = useState(null);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true); // Set loading to true when component mounts
    const unsubscribe = onSnapshot(collection(db, "Reports"), (snapshot) => {
      const reportsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort the reports by ID in ascending order
      reportsList.sort((a, b) => b.id.localeCompare(a.id));

      setReports(reportsList);
      setIsLoading(false); // Set loading to false after reports are fetched
    });

    return () => unsubscribe();
  }, []);

  const generateReportId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().substr(-2); // Get last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-11, hence +1), pad with zero if needed
    const day = now.getDate().toString().padStart(2, "0"); // Get day of the month, pad with zero if needed
    const seconds = (
      now.getHours() * 3600 +
      now.getMinutes() * 60 +
      now.getSeconds()
    )
      .toString()
      .padStart(5, "0"); // Convert current time to seconds, ensure it's 5 digits

    return `RPT-${year}-${month}-${day}-${seconds}`;
  };

  const openGenerateModal = () => {
    const newReportId = generateReportId();
    setSelectedReport({
      reportID: newReportId,
      date: new Date().toLocaleDateString(),
    });
    setIsGenerateModalOpen(true);
  };

  const downloadNonVatReport = async (report: any) => {
    const pdf = new jsPDF();
  
    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15);
  
    // Set up some initial positions
    let yPos = 35;
    const lineHeight = 8;
    const pageHeight = pdf.internal.pageSize.height;
    const bottomMargin = 20; // Margin from the bottom of the page
    const storeGap = 5; // Gap between stores
  
    // Function to check if we need to add a new page and redraw lines
    const checkPageOverflow = () => {
      if (yPos + lineHeight > pageHeight - bottomMargin) {
        pdf.addPage();
        yPos = 20; // Reset yPos for the new page
  
        // Redraw column headers and vertical lines on new page
        pdf.setFontSize(10);
        pdf.text("CUSTOMER", 10, yPos);
        pdf.text("INVOICE ID", 85, yPos);
        pdf.text("TOTAL AMOUNT", 160, yPos);
        yPos += lineHeight;
        pdf.line(10, yPos, 200, yPos); // Draw the line under headers
        yPos += lineHeight;
  
        const leftMargin = 10;
        const columnWidths = [75, 75, 40];
        let xPos = leftMargin;
  
        columnWidths.forEach(width => {
          pdf.line(xPos, yPos - lineHeight, xPos, pageHeight - bottomMargin);
          xPos += width;
        });
  
        pdf.line(200, yPos - lineHeight, 200, pageHeight - bottomMargin); // Final right vertical line
      }
    };
  
    // Report ID and Date
    pdf.text(`Report ID: ${report.reportID}`, 10, yPos);
    pdf.text(`Date: ${report.date}`, 10, (yPos += lineHeight));
    pdf.text(`VAT Registration Number: 459421476`, 10, (yPos += lineHeight));
  
    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 2;
  
    // Report Details
    pdf.text(`Invoice Date Range: ${report.dateRange || "N/A"}`, 10, yPos);
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(
      `Number of Invoices: ${
        report.invoices.filter((invoice: any) => invoice.totalPrice > 0).length
      }`,
      10,
      yPos
    );
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(
      `Total Price: £${report.invoices
        .filter((invoice: any) => invoice.totalPrice > 0) // Filter to only include invoices with a totalPrice
        .reduce(
          (sum: number, invoice: any) => sum + (invoice.totalPrice || 0),
          0
        )
        .toFixed(2)}`,
      10,
      yPos
    );
    checkPageOverflow();
    yPos += lineHeight;
  
    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 1.5;
  
    // Set smaller font size for headers
    pdf.setFontSize(10);
  
    // Render table headers
    pdf.text("CUSTOMER", 10, yPos);
    pdf.text("INVOICE ID", 85, yPos);
    pdf.text("TOTAL AMOUNT", 160, yPos);
    checkPageOverflow();
    yPos += lineHeight;
    pdf.line(10, yPos, 200, yPos); // Draw the line under headers
    yPos += lineHeight;
  
    // Draw vertical lines for the columns
    const leftMargin = 10;
    const columnWidths = [75, 75, 40];
    let xPos = leftMargin;
  
    columnWidths.forEach(width => {
      pdf.line(xPos, yPos - lineHeight, xPos, pageHeight - bottomMargin);
      xPos += width;
    });
  
    pdf.line(200, yPos - lineHeight, 200, pageHeight - bottomMargin); // Final right vertical line
  
    // Sort invoices by customer ID
    const sortedInvoices = report.invoices
      .filter((invoice: any) => invoice.totalPrice > 0)
      .sort((a: any, b: any) => a.storeId.localeCompare(b.storeId));
  
    // Render each store's invoices
    let currentStoreId = null;
  
    sortedInvoices.forEach((invoice: any, index: number) => {
      // Add a gap and horizontal line between different stores
      if (currentStoreId !== invoice.storeId && currentStoreId !== null) {
        yPos += storeGap;
        pdf.line(10, yPos, 200, yPos); // Draw horizontal line
        yPos += storeGap; // Add additional gap after the line
        checkPageOverflow();
      }
  
      currentStoreId = invoice.storeId;
  
      // Wrap store name if too long
      const storeNameXPos = 12;
      const storeNameWidth = 70;
  
      // Set larger font for Store Name and wrap text if necessary
      pdf.setFontSize(10);
      pdf.text(invoice.storeName, storeNameXPos, yPos + 3, { maxWidth: storeNameWidth }); // Align left within the column
  
      // Set font size back to normal for the other content and align left
      pdf.setFontSize(10);
      pdf.text(`${invoice.invoiceID}`, 87, yPos); // Invoice ID
      pdf.text(`£${invoice.totalPrice.toFixed(2)}`, 162, yPos); // Total Amount
      checkPageOverflow();
      yPos += lineHeight;
    });
  
    // Save the PDF
    pdf.save(`${report.reportID}-NON-VAT.pdf`);
  };
  

  const downloadVatReport = async (report: any) => {
    const pdf = new jsPDF();
  
    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15);
  
    // Set up some initial positions
    let yPos = 35;
    const lineHeight = 8;
    const pageHeight = pdf.internal.pageSize.height;
    const bottomMargin = 20; // Margin from the bottom of the page
    const storeGap = 5; // Gap between stores
  
    // Function to check if we need to add a new page and redraw lines
    const checkPageOverflow = () => {
      if (yPos + lineHeight > pageHeight - bottomMargin) {
        pdf.addPage();
        yPos = 20; // Reset yPos for the new page
  
        // Redraw column headers and vertical lines on new page
        pdf.setFontSize(10);
        pdf.text("CUSTOMER", 10, yPos);
        pdf.text("INVOICE DATE/ID", 55, yPos);
        pdf.text("Pre-VAT Amount", 95, yPos);
        pdf.text("VAT Amount", 130, yPos);
        pdf.text("TOTAL AMOUNT", 165, yPos);
        yPos += lineHeight;
        pdf.line(10, yPos, 200, yPos); // Draw the line under headers
        yPos += lineHeight;
  
        const leftMargin = 10;
        const columnWidths = [45, 40, 35, 35, 35];
        let xPos = leftMargin;
  
        columnWidths.forEach(width => {
          pdf.line(xPos, yPos - lineHeight, xPos, pageHeight - bottomMargin);
          xPos += width;
        });
  
        pdf.line(200, yPos - lineHeight, 200, pageHeight - bottomMargin); // Final right vertical line
      }
    };
  
    // Report ID and Date
    pdf.text(`Report ID: ${report.reportID}`, 10, yPos);
    pdf.text(`Date: ${report.date}`, 10, (yPos += lineHeight));
    pdf.text(`VAT Registration Number: 459421476`, 10, (yPos += lineHeight));
  
    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 2;
  
    // Calculate the total VAT price
    const totalVatPrice = report.invoices
      .filter((invoice: any) => invoice.totalPriceVat > 0)
      .reduce(
        (sum: number, invoice: any) => sum + (invoice.totalPriceVat || 0),
        0
      );
  
    // Calculate the total price before VAT (assuming VAT is 20%)
    const totalPriceBeforeVat = totalVatPrice / 1.2;
  
    // Calculate the VAT amount
    const vatAmount = totalVatPrice - totalPriceBeforeVat;
  
    // Report Details
    pdf.text(`Invoice Date Range: ${report.dateRange || "N/A"}`, 10, yPos);
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(
      `Number of Invoices: ${
        report.invoices.filter((invoice: any) => invoice.totalPriceVat > 0).length
      }`,
      10,
      yPos
    );
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(
      `Total Price Before VAT: £${totalPriceBeforeVat.toFixed(2)}`,
      10,
      yPos
    );
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(`VAT Amount: £${vatAmount.toFixed(2)}`, 10, yPos);
    checkPageOverflow();
    yPos += lineHeight;
    pdf.text(`Total Price With VAT: £${totalVatPrice.toFixed(2)}`, 10, yPos);
    checkPageOverflow();
    yPos += lineHeight;
  
    // Separator line
    yPos += lineHeight / 2;
    checkPageOverflow();
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 1.5;
  
    // Set smaller font size for headers
    pdf.setFontSize(10);
  
    // Render table headers
    pdf.text("CUSTOMER", 10, yPos);
    pdf.text("INVOICE ID", 55, yPos);
    pdf.text("Pre-VAT Amount", 95, yPos);
    pdf.text("VAT Amount", 130, yPos);
    pdf.text("TOTAL AMOUNT", 165, yPos);
    checkPageOverflow();
    yPos += lineHeight;
    pdf.line(10, yPos, 200, yPos); // Draw the line under headers
    yPos += lineHeight;
  
    // Draw vertical lines for the columns
    const leftMargin = 10;
    const columnWidths = [45, 40, 35, 35, 35];
    let xPos = leftMargin;
  
    columnWidths.forEach(width => {
      pdf.line(xPos, yPos - lineHeight, xPos, pageHeight - bottomMargin);
      xPos += width;
    });
  
    pdf.line(200, yPos - lineHeight, 200, pageHeight - bottomMargin); // Final right vertical line
  
    // Sort invoices by customer ID
    const sortedInvoices = report.invoices
      .filter((invoice: any) => invoice.totalPriceVat > 0)
      .sort((a: any, b: any) => a.storeId.localeCompare(b.storeId));
  
    // Render each store's invoices
    let currentStoreId = null;
  
    sortedInvoices.forEach((invoice: any, index: number) => {
      const totalPriceBeforeVat = invoice.totalPriceVat / 1.2;
      const vatAmount = invoice.totalPriceVat - totalPriceBeforeVat;
  
      // Add a gap and horizontal line between different stores
      if (currentStoreId !== invoice.storeId && currentStoreId !== null) {
        yPos += storeGap;
        pdf.line(10, yPos, 200, yPos); // Draw horizontal line
        yPos += storeGap; // Add additional gap after the line
        checkPageOverflow();
      }
  
      currentStoreId = invoice.storeId;
  
      // Wrap store name if too long
      const storeNameXPos = 12;
      const storeNameWidth = 40;
  
      // Set larger font for Store Name and wrap text if necessary
      pdf.setFontSize(10);
      pdf.text(invoice.storeName, storeNameXPos, yPos + 3, { maxWidth: storeNameWidth }); // Align left within the column
  
      // Set font size back to normal for the other content and align left
      pdf.setFontSize(10);
      pdf.text(`${invoice.invoiceID}`, 57, yPos); // Invoice ID
      pdf.text(`£${totalPriceBeforeVat.toFixed(2)}`, 97, yPos); // Pre-VAT Amount
      pdf.text(`£${vatAmount.toFixed(2)}`, 132, yPos); // VAT Amount
      pdf.text(`£${invoice.totalPriceVat.toFixed(2)}`, 167, yPos); // Total Amount
      checkPageOverflow();
      yPos += lineHeight;
    });
  
    // Save the PDF
    pdf.save(`${report.reportID}-VAT.pdf`);
  };
  
  
  
  
  
  
  

  const downloadNonVatReportO = async (report: any) => {
    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15);

    // Set up some initial positions
    let yPos = 35;
    const lineHeight = 8;

    // Report ID and Date
    pdf.text(`Report ID: ${report.reportID}`, 10, yPos);
    pdf.text(`Date: ${report.date}`, 10, (yPos += lineHeight));
    pdf.text(`VAT Registration Number: 459421476`, 10, (yPos += lineHeight));

    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 2;

    // Report Details
    pdf.text(`Invoice Date Range: ${report.dateRange || "N/A"}`, 10, yPos);
    yPos += lineHeight;
    pdf.text(
      `Number of Invoices: ${
        report.invoices.filter((invoice: any) => invoice.totalPrice > 0).length
      }`,
      10,
      yPos
    );
    yPos += lineHeight;
    pdf.text(
      `Total Price: £${report.invoices
        .reduce(
          (sum: number, invoice: any) => sum + (invoice.totalPrice || 0),
          0
        )
        .toFixed(2)}`,
      10,
      yPos
    );
    yPos += lineHeight;

    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 1.5;

    // Render each invoice with Non-VAT prices only
    report.invoices
      .filter((invoice: any) => invoice.totalPrice > 0)
      .forEach((invoice: any) => {
        pdf.text(`Invoice ID: ${invoice.invoiceID}`, 10, yPos);
        yPos += lineHeight;
        pdf.text(`Non-VAT Price: £${invoice.totalPrice.toFixed(2)}`, 20, yPos);
        yPos += lineHeight * 1.5;
      });

    // Save the PDF
    pdf.save(`${report.reportID}-NON-VAT.pdf`);
  };

  const downloadVatReportO = async (report: any) => {
    const pdf = new jsPDF();

    // Add business icon
    pdf.addImage(bIcon, "JPEG", 10, 10, 30, 15);

    // Set up some initial positions
    let yPos = 35;
    const lineHeight = 8;

    // Report ID and Date
    pdf.text(`Report ID: ${report.reportID}`, 10, yPos);
    pdf.text(`Date: ${report.date}`, 10, (yPos += lineHeight));
    pdf.text(`VAT Registration Number: 459421476`, 10, (yPos += lineHeight));

    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 2;

    // Calculate the total VAT price
    const totalVatPrice = report.invoices
      .filter((invoice: any) => invoice.totalPriceVat > 0)
      .reduce(
        (sum: number, invoice: any) => sum + (invoice.totalPriceVat || 0),
        0
      );

    // Calculate the total price before VAT (assuming VAT is 20%)
    const totalPriceBeforeVat = totalVatPrice / 1.2;

    // Calculate the VAT amount
    const vatAmount = totalVatPrice - totalPriceBeforeVat;

    // Report Details
    pdf.text(`Invoice Date Range: ${report.dateRange || "N/A"}`, 10, yPos);
    yPos += lineHeight;
    pdf.text(
      `Number of Invoices: ${
        report.invoices.filter((invoice: any) => invoice.totalPriceVat > 0)
          .length
      }`,
      10,
      yPos
    );
    yPos += lineHeight;
    pdf.text(
      `Total Price Before VAT: £${totalPriceBeforeVat.toFixed(2)}`,
      10,
      yPos
    );
    yPos += lineHeight;
    pdf.text(`VAT Amount: £${vatAmount.toFixed(2)}`, 10, yPos);
    yPos += lineHeight;
    pdf.text(`Total Price With VAT: £${totalVatPrice.toFixed(2)}`, 10, yPos);
    yPos += lineHeight;

    // Separator line
    yPos += lineHeight / 2;
    pdf.line(10, yPos, 200, yPos);
    yPos += lineHeight * 1.5;

    // Render each invoice with VAT prices only
    report.invoices
      .filter((invoice: any) => invoice.totalPriceVat > 0)
      .forEach((invoice: any) => {
        pdf.text(`Invoice ID: ${invoice.invoiceID}`, 10, yPos);
        yPos += lineHeight;
        pdf.text(`VAT Price: £${invoice.totalPriceVat.toFixed(2)}`, 20, yPos);
        yPos += lineHeight * 1.5;
      });

    // Save the PDF
    pdf.save(`${report.reportID}-VAT.pdf`);
  };

  const gridContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))", // This will create a responsive grid layout
    gap: "60px 20px", // Space between grid items, "vertical horizontal"
    padding: "20px", // Padding around the entire grid
  };

  const reportCardStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Space out the content inside the card
    height: "100%", // Make sure all cards are of equal height
  };

  const downloadReportButtonStyle: React.CSSProperties = {
    backgroundColor: "#1c345d", // Example blue color
    color: "white",
    padding: "10px 20px",
    margin: "10px",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const scrollableContainerStyle: React.CSSProperties = {
    height: "75vh", // Adjust the height as needed
    overflowY: "auto", // Enable vertical scrolling
    padding: "0 20px", // Optional: padding for better aesthetics
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
          marginLeft: 40,
        }}
      >
        <h1>Reports</h1>
        <div>
          <button style={downloadReportButtonStyle} onClick={openGenerateModal}>
            Generate Report
          </button>
        </div>
      </div>
      <div style={scrollableContainerStyle}>
        <div style={gridContainerStyle}>
          {isLoading ? (
            <p>Loading...</p>
          ) : reports.length > 0 ? (
            reports.map((report) => (
              <div key={report.id} style={reportCardStyle}>
                <h3>Report ID: {report.id}</h3>
                <p>Date: {report.date}</p>
                <p>Total Price: £{report.totalPrice.toFixed(2)}</p>
                <p>Total VAT Price: £{report.totalPriceVat.toFixed(2)}</p>
                <p>Number of Invoices: {report.numberOfInvoices}</p>
                <p>Date Range: {report.dateRange}</p>
                <button
                  style={downloadReportButtonStyle}
                  onClick={() => downloadNonVatReport(report)}
                >
                  Download Report NON VAT
                </button>
                <button
                  style={downloadReportButtonStyle}
                  onClick={() => downloadVatReport(report)}
                >
                  Download Report VAT
                </button>
              </div>
            ))
          ) : (
            <p>No reports found.</p>
          )}
        </div>
      </div>

      {isGenerateModalOpen && selectedReport && (
        <GenerateReportModal
          isOpen={isGenerateModalOpen}
          onClose={() => setIsGenerateModalOpen(false)}
          report={selectedReport}
        />
      )}
    </div>
  );
};

export default Reports;
