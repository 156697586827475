//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import { useLanguage } from "../LanguageContext";
import { db } from "../App";
import AddWarehouseModal from "./AddWarehouseModal";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
  limit,
  startAfter,
  orderBy, // Import orderBy here
  writeBatch,
} from "firebase/firestore";
import { processHistoryLogClient } from "./Functions";
import {
  formatBase64Image,
  formatEpochDate,
  formatEpochTime,
} from "./Functions";
import {
  FaBars,
  FaFileInvoiceDollar,
  FaShoppingCart,
  FaFilter,
  FaExclamationCircle,
} from "react-icons/fa";
import GradientContainer from "./GradientContainer";
import AddToCartModal from "./AddToCartModal";
import CartModal from "./CartModal";
import {
  sendPasswordResetEmail,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Modal from "react-modal";

import ProfileModal from "./ProfileModal";

import ScanSuccessModal from "./ScanSuccessModal"; // Import the modal
import { fontStyle } from "html2canvas/dist/types/css/property-descriptors/font-style";

const Products: React.FC = () => {
  const { language } = useLanguage();

  const translations = {
    Warehouse: language === "kurdish" ? "KURDISH FOR WAREHOUSE" : "Warehouse",
    New: language === "kurdish" ? "+ نوێ" : "NEW +",
    Products: language === "kurdish" ? "kurdish for products" : "Products",
    Title: language === "kurdish" ? ":ناونیشان" : "Title:",
    Body: language === "kurdish" ? ":ناوەڕۆک" : "Body:",
    Recipients: language === "kurdish" ? ":وەرگرەکان" : "Recipients:",
    Date: language === "kurdish" ? ":بەروار" : "Date:",
  };

  interface WarehouseEntry {
    id: string;
    imageUrl: string;
    name: string;
    description: string;
    quantity: number;
    // Add other properties here as needed
  }

  interface CartItem extends WarehouseEntry {
    quantity: number;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showOverlay, setShowOverlay] = useState(true); // Initially show overlay
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState(""); // State to store the selected store ID

  const [isAccessedFromSidebar, setIsAccessedFromSidebar] = useState(false); // State to determine if accessed from sidebar

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const [pageSize, setPageSize] = useState(40); // Number of items per page
  const [lastVisible, setLastVisible] = useState(null); // Last visible document snapshot for pagination
  const [hasMore, setHasMore] = useState(true);

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  useEffect(() => {
    // Check if the component is accessed from the sidebar menu
    const isFromSidebar = window.location.pathname === "/index";
    setIsAccessedFromSidebar(isFromSidebar);

    if (isFromSidebar) {
      // If accessed from sidebar, skip login and set logged in as admin
      setIsLoggedIn(true);
      setSelectedStoreId("admin");
    }
  }, []);

  const handleLogin = async () => {
    try {
      // List of valid admin emails
      const validAdminEmails = [
        "admin@apsoft.com",
        "admin1@apsoft.com",
        "admin2@apsoft.com",
      ];
      const adminPassword = "251081";

      const inputEmail = login.toLowerCase(); // Convert input email to lowercase

      if (validAdminEmails.includes(inputEmail) && password === adminPassword) {
        //console.log("ADMINADMINADMIN");
        // If the provided credentials match any of the admin credentials
        const storeId = inputEmail.split("@")[0]; // Extract "admin", "admin1", or "admin2" part
        setIsLoggedIn(true);
        setShowOverlay(false); // Hide overlay upon successful login
        setSelectedStoreId(storeId); // Set selectedStoreId based on the prefix (admin/admin1/admin2)
      } else {
        // Use Firebase Authentication to validate user credentials
        const userCredential = await signInWithEmailAndPassword(
          auth,
          inputEmail,
          password
        );
        // Successfully logged in
        setIsLoggedIn(true);
        setShowOverlay(false); // Hide overlay upon successful login

        // Retrieve the UID of the logged-in user and set it as the selectedStoreId
        const loggedInUserId = userCredential.user.uid;
        setSelectedStoreId(loggedInUserId); // Set selectedStoreId as the UID for the logged-in user
      }
    } catch (error) {
      console.error("Error logging in:", error);
      // Handle any errors that occur during login
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        alert("Invalid login or password. Please try again.");
      } else {
        alert("An error occurred during login. Please try again later.");
      }
    }
  };

  useEffect(() => {
    console.log("selectedStoreId updated:", selectedStoreId);
  }, [selectedStoreId]);

  const handleLogout = () => {
    // Reset login and password fields and set isLoggedIn to false
    setLogin("");
    setPassword("");
    setIsLoggedIn(false);
    setShowOverlay(true); // Show overlay again after logout
  };

  const auth = getAuth();

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, login);
      alert("Password reset email sent!");
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      alert(
        "Failed to send password reset email. Please check the email address and try again."
      );
    }
  };

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showDropdown, setShowDropdown] = useState(false); // New state to toggle dropdown visibility
  const [barcodeInput, setBarcodeInput] = useState("");
  const [nameInput, setNameInput] = useState(""); // New state for the name filter

  const [addToCartProduct, setAddToCartProduct] =
    useState<WarehouseEntry | null>(null);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const [warehouseEntries, setWarehouseEntries] = useState<WarehouseEntry[]>(
    []
  );
  const [isCartModalOpen, setIsCartModalOpen] = useState(false); // State to manage CartModal visibility
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [isScanSuccessModalOpen, setIsScanSuccessModalOpen] = useState(false);
  const [scannedProduct, setScannedProduct] = useState<WarehouseEntry | null>(
    null
  );

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.unitPrice * item.quantity,
    0
  );

  const [selectedWarehouseEntry, setSelectedWarehouseEntry] = useState<
    string | null
  >(null); // Track the selected UID

  useEffect(() => {
    // Only set up the listener if a store ID is selected
    if (!selectedStoreId) return;

    const cartRef = doc(db, "Cart", selectedStoreId || "admin");

    const unsubscribe = onSnapshot(
      cartRef,
      async (cartSnap) => {
        if (cartSnap.exists()) {
          const cartData = cartSnap.data();
          const fetchedCartItems = await Promise.all(
            cartData.items.map(async (item) => {
              const productRef = doc(db, "Warehouse", item.id);
              const productSnap = await getDoc(productRef);
              if (productSnap.exists()) {
                const productData = productSnap.data() as WarehouseEntry;
                return {
                  ...productData,
                  quantity: item.quantity,
                  docId: productSnap.id, // Add the document ID to the cart item
                };
              }
              return null;
            })
          );
          setCartItems(
            fetchedCartItems.filter((item): item is CartItem => item !== null)
          );
        } else {
          setCartItems([]);
        }
      },
      (error) => {
        console.error("Error fetching cart items:", error);
        alert(
          "An error occurred while fetching cart items. Please try again later."
        );
      }
    );

    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, [selectedStoreId]);

  const addToCart = async (product: WarehouseEntry, quantity: number) => {
    setIsProcessing(true);

    const productId = product?.id || product?.docId;
    const quantityValue = parseInt(quantity.toString(), 10);

    if (!productId || isNaN(quantityValue) || quantityValue <= 0) {
      alert("Invalid product details or quantity.");
      setIsProcessing(false);
      return;
    }

    const warehouseItemRef = doc(db, "Warehouse", productId);
    const warehouseItemSnap = await getDoc(warehouseItemRef);

    if (!warehouseItemSnap.exists()) {
      alert("Product not found in the warehouse.");
      setIsProcessing(false);
      return;
    }

    const warehouseItemData = warehouseItemSnap.data();
    const currentReserved = warehouseItemData.reserved || 0;
    const availableQuantity = warehouseItemData.quantity - currentReserved;

    if (quantityValue > availableQuantity) {
      setNoMoreQuantitiesAvailable(true); // Set state to indicate no more quantities available
      alert(`Only ${availableQuantity} items available for reservation.`);
      setIsProcessing(false);
      return;
    }

    const newReserved = currentReserved + quantityValue;

    const cartRef = doc(db, "Cart", selectedStoreId || "admin");
    const cartSnap = await getDoc(cartRef);
    const batch = writeBatch(db);

    // Update or create cart
    if (cartSnap.exists()) {
      const cartData = cartSnap.data();
      const itemIndex = cartData.items.findIndex(
        (item: any) => item.id === productId
      );
      if (itemIndex >= 0) {
        cartData.items[itemIndex].quantity += quantityValue;
      } else {
        cartData.items.push({ id: productId, quantity: quantityValue });
      }
      batch.set(cartRef, { items: cartData.items }, { merge: true });
    } else {
      batch.set(cartRef, {
        items: [{ id: productId, quantity: quantityValue }],
      });
    }

    // Reserve inventory in the warehouse
    batch.update(warehouseItemRef, { reserved: newReserved });

    // Log the reservation
    processHistoryLogClient(productId, selectedStoreId, "reserve", {
      previousReserved: currentReserved,
      newReserved: newReserved,
      quantity: warehouseItemData.quantity, // Log the current warehouse quantity
      additionalInfo: {
        reservedBy: selectedStoreId,
        reservedQuantity: quantityValue,
      },
    });

    try {
      await batch.commit();
      setQuantity("");
      setBarcodeInput(""); // Clear the barcode input for the next scan
      //barcodeInputRef.current?.focus(); // Refocus the input field
      setIsProcessing(false);
    } catch (error) {
      console.error("Error updating cart and reservations: ", error);
      setQuantity("");
      alert("Failed to add product to cart and update reservations.");
      setIsProcessing(false);
    }
  };

  // This assumes you modify the place where handleAddToCart is called to pass the product as well
  const handleAddToCart = (product: WarehouseEntry, quantity: number) => {
    const existingCartItemIndex = cartItems.findIndex(
      (item) => item.id === product.id
    );

    let totalInCart = quantity;
    if (existingCartItemIndex !== -1) {
      // If the product is already in the cart, add the new quantity to the existing quantity
      totalInCart += cartItems[existingCartItemIndex].quantity;
    }

    // Check if the total quantity exceeds the available stock
    if (totalInCart > product.quantity) {
      alert(
        `Only ${product.quantity} available. You already have ${
          existingCartItemIndex !== -1
            ? cartItems[existingCartItemIndex].quantity
            : 0
        } in the cart. Please adjust the quantity.`
      );
      return;
    }

    // If the product is already in the cart, just update the quantity
    if (existingCartItemIndex !== -1) {
      const updatedCartItems = cartItems.map((item, index) => {
        if (index === existingCartItemIndex) {
          return { ...item, quantity: totalInCart };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    } else {
      // If the product is not in the cart, add it with the specified quantity
      setCartItems([...cartItems, { ...product, quantity }]);
    }

    // Close the modal
    setAddToCartProduct(null);
  };

  const toggleCartModal = () => {
    setIsCartModalOpen(!isCartModalOpen);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  //!!this will break everything cause it doesnt unreserve dont use this!!
  const clearCart = () => {
    setCartItems([]); // Clear the cart items state
  };

  const toggleDropdown = () => {
    console.log("Toggling Dropdown");
    setShowDropdown(!showDropdown);
  };

  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for the dropdown

  // Function to handle outside click
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false); // Close the dropdown if click is outside
    }
  };

  useEffect(() => {
    // Add when the component mounts
    document.addEventListener("mousedown", handleOutsideClick);
    // Return function to be called when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  // You might want to close the dropdown when a category is selected
  const handleCategorySelect = (category) => {
    setSelectedCategory(category); // Set selectedCategory to the chosen category
    setShowDropdown(false); // Close the dropdown
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "Categories"),
      (querySnapshot) => {
        const fetchedCategories = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(fetchedCategories);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Call searchByBarcode whenever barcodeInput changes
    if (barcodeInput.trim()) {
      searchByBarcode(barcodeInput.trim());
    } else {
      // If barcodeInput is cleared, load initial items
      setLastVisible(null); // Reset lastVisible for new search
      setHasMore(true); // Reset hasMore for new search
      loadInitialItems();
    }
  }, [barcodeInput]);

  const loadInitialItems = async () => {
    setIsLoading(true);

    let q = collection(db, "Warehouse");

    if (selectedCategory !== "All") {
      q = query(q, where("category", "==", selectedCategory));
    }

    q = query(
      q,
      orderBy("timestamp", "desc"), // Order by timestamp only
      limit(pageSize)
    );

    const querySnapshot = await getDocs(q);
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    const updatedEntries = querySnapshot.docs
      .map((doc) => {
        const data = doc.data();
        let formattedDate = "";
        let formattedDateTime = "";

        let timestamp = new Date(); // Default to current date if no timestamp
        if (data.timestamp) {
          if (data.timestamp.toDate) {
            timestamp = data.timestamp.toDate();
          } else if (typeof data.timestamp === "number") {
            timestamp = new Date(data.timestamp);
          } else {
            console.error("Unrecognized timestamp format:", data.timestamp);
          }
        }

        formattedDate = formatEpochDate(timestamp.getTime());
        formattedDateTime = formatEpochTime(timestamp.getTime());

        return {
          ...data,
          id: doc.id,
          formattedDate,
          formattedDateTime,
          quantity: (data.quantity || 0) - (data.reserved || 0),
          priority: data.priority !== undefined ? data.priority : Infinity, // Default priority
        };
      })
      .filter((entry) => !entry.archived)
      .sort((a, b) => a.priority - b.priority); // Sort by priority, lower numbers first

    setWarehouseEntries(updatedEntries);
    setLastVisible(lastVisibleDoc); // Set last visible document for pagination
    setHasMore(querySnapshot.docs.length === pageSize); // If the query returns less than pageSize, then there are no more items
    setIsLoading(false);
  };

  // Initialize items when selectedCategory changes or on component mount
  useEffect(() => {
    if (!barcodeInput.trim()) {
      setLastVisible(null); // Reset lastVisible for new category or initial load
      setHasMore(true); // Reset hasMore for new category or initial load
      loadInitialItems();
    }
  }, [selectedCategory, pageSize, barcodeInput]);

  const observerTargetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoggedIn || !observerTargetRef.current || !hasMore || isLoading)
      return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          console.log("Observer target is intersecting. Loading more items...");
          loadMoreItems();
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the target is visible
      }
    );

    if (observerTargetRef.current) {
      observer.observe(observerTargetRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (observerTargetRef.current) {
        observer.unobserve(observerTargetRef.current);
      }
    };
  }, [isLoggedIn, hasMore, isLoading, lastVisible]);

  const loadMoreItems = async () => {
    if (!lastVisible || !hasMore) return;

    let q = collection(db, "Warehouse");

    if (selectedCategory !== "All") {
      q = query(q, where("category", "==", selectedCategory));
    }

    q = query(
      q,
      orderBy("timestamp", "desc"), // Order by timestamp only
      startAfter(lastVisible),
      limit(pageSize)
    );

    const querySnapshot = await getDocs(q);
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    const newEntries = querySnapshot.docs
      .map((doc) => {
        const data = doc.data();
        let formattedDate = "";
        let formattedDateTime = "";

        let timestamp = new Date(); // Default to current date if no timestamp
        if (data.timestamp) {
          if (data.timestamp.toDate) {
            timestamp = data.timestamp.toDate();
          } else if (typeof data.timestamp === "number") {
            timestamp = new Date(data.timestamp);
          } else {
            console.error("Unrecognized timestamp format:", data.timestamp);
          }
        }

        formattedDate = formatEpochDate(timestamp.getTime());
        formattedDateTime = formatEpochTime(timestamp.getTime());

        return {
          ...data,
          id: doc.id,
          formattedDate,
          formattedDateTime,
          quantity: (data.quantity || 0) - (data.reserved || 0),
          priority: data.priority !== undefined ? data.priority : Infinity, // Default priority
        };
      })
      .filter((entry) => !entry.archived)
      .sort((a, b) => a.priority - b.priority); // Sort by priority, lower numbers first

    setWarehouseEntries((prevEntries) => [...prevEntries, ...newEntries]);
    setLastVisible(lastVisibleDoc); // Update last visible document
    setHasMore(querySnapshot.docs.length === pageSize); // Check if there are more items
  };

  const searchByBarcode = async (barcode: string) => {
    try {
      const q = query(
        collection(db, "Warehouse"),
        where("barcode", "==", barcode),
        limit(1) // We assume barcode is unique, so we limit the result to 1
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        let formattedDate = "";
        let formattedDateTime = "";

        let timestamp = new Date(); // Default to current date if no timestamp
        if (data.timestamp) {
          if (data.timestamp.toDate) {
            timestamp = data.timestamp.toDate();
          } else if (typeof data.timestamp === "number") {
            timestamp = new Date(data.timestamp);
          } else {
            console.error("Unrecognized timestamp format:", data.timestamp);
          }
        }

        formattedDate = formatEpochDate(timestamp.getTime());
        formattedDateTime = formatEpochTime(timestamp.getTime());

        const entry = {
          ...data,
          id: doc.id,
          formattedDate,
          formattedDateTime,
          quantity: (data.quantity || 0) - (data.reserved || 0),
        };

        // Set the warehouse entries state to only show the searched entry
        setWarehouseEntries([entry]);
        setLastVisible(null); // Reset lastVisible for new search
        setHasMore(false); // No more items to load for a specific search
      } else {
        setWarehouseEntries([]); // No results found
        setLastVisible(null); // Reset lastVisible for new search
        setHasMore(false); // No more items to load
      }
    } catch (error) {
      console.error("Error searching by barcode:", error);
    }
  };

  const [isFastScanMode, setIsFastScanMode] = useState(false);
  const [noMoreQuantitiesAvailable, setNoMoreQuantitiesAvailable] =
    useState(false);

  const barcodeInputRef = useRef<HTMLInputElement>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [quantity, setQuantity] = useState(""); // Default quantity to 1

  const toggleFastScanMode = () => {
    setIsFastScanMode(!isFastScanMode);
    if (!isFastScanMode) {
      setScannedProduct(null); // Reset scanned product to null
      setIsScanSuccessModalOpen(true); // Open modal immediately when enabling Fast Scan mode
    } else {
      setIsScanSuccessModalOpen(false); // Close modal when exiting Fast Scan mode
    }
  };

  const handleBarcodeScan = async (barcode: string) => {
    try {
      const q = query(
        collection(db, "Warehouse"),
        where("barcode", "==", barcode),
        limit(1) // We assume barcode is unique, so we limit the result to 1
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        let formattedDate = "";
        let formattedDateTime = "";

        let timestamp = new Date(); // Default to current date if no timestamp
        if (data.timestamp) {
          if (data.timestamp.toDate) {
            timestamp = data.timestamp.toDate();
          } else if (typeof data.timestamp === "number") {
            timestamp = new Date(data.timestamp);
          } else {
            console.error("Unrecognized timestamp format:", data.timestamp);
          }
        }

        formattedDate = formatEpochDate(timestamp.getTime());
        formattedDateTime = formatEpochTime(timestamp.getTime());

        const entry = {
          ...data,
          id: doc.id,
          formattedDate,
          formattedDateTime,
          quantity: (data.quantity || 0) - (data.reserved || 0),
        };

        addToCart(entry, 1);

        setScannedProduct(entry); // Set the scanned product
        setIsScanSuccessModalOpen(true); // Open the success modal

        setBarcodeInput(""); // Clear the barcode input for the next scan
        //barcodeInputRef.current?.focus(); // Refocus the input field
      } else {
        alert("Item not found.");
        setBarcodeInput(""); // Clear the barcode input
        //barcodeInputRef.current?.focus(); // Refocus the input field
      }
    } catch (error) {
      console.error("Error searching by barcode:", error);
    }
  };

  const handleBarcodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeInput(e.target.value);
    // Perform search based on barcode input
    if (e.target.value.trim()) {
      searchByBarcode(e.target.value.trim());
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFilterChange = (newFilters: {
    area: string;
    orderId: string;
    status: string;
  }) => {
    //setFilters(newFilters);
  };

  const totalItems = cartItems.reduce(
    (total, currentItem) => total + currentItem.quantity,
    0
  );

  const headerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Stack items vertically
    color: "black",
    backgroundColor: "white",
    //borderRadius: 5,
    //margin: "0px 30px",
    padding: "0.5rem 1rem",
    position: "relative", // This ensures dropdown positions itself relative to the header
  };

  const titleAndCartContainerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start", // Align items at the top
    width: "100%", // Ensure this container spans the full width of its parent
  };

  const categoryIndicatorStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center", // Center items vertically
    cursor: "pointer",
    fontSize: "17px",
    lineHeight: "24px", // Adjust to match icon size for better alignment
    marginTop: 0, // Remove margin to align properly
  };

  const selectListStyle: React.CSSProperties = {
    marginTop: "20px", // Add space between the title and the select list
    width: "30%",
  };

  const scrollableContainerStyles: React.CSSProperties = {
    maxHeight: isAccessedFromSidebar
      ? "calc(100vh - 300px)"
      : "calc(100vh - 160px)", // Adjust the height based on the mode
    overflowY: "auto",
    overflowX: "hidden", // Prevent horizontal scrolling
    //marginTop: 10,
  };

  const gridStyles: React.CSSProperties = {
    display: "grid",
    backgroundColor: "transparent",
    gridTemplateColumns: isMobile
      ? "repeat(auto-fill, minmax(120px, 1fr))" // For mobile
      : "repeat(auto-fill, minmax(200px, 1fr))", // For desktop
    gap: isMobile ? "10px" : "20px", // Less gap for mobile
    padding: isMobile ? "10px" : "20px", // Less padding for mobile
    //paddingBottom: 100,
  };

  const dropdownStyles: React.CSSProperties = {
    position: "absolute",
    top: "100%", // Position directly below the toggle element
    left: 0,
    backgroundColor: "white",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    width: "200px", // Adjust as needed
    border: "1px solid #ddd",
    maxHeight: "300px", // Set a max height for the dropdown
    overflowY: "auto", // Enable vertical scrolling
  };

  const inputStyle: React.CSSProperties = {
    paddingLeft: "10px",
    flex: 1,

    width: "25%", // Example fixed width, adjust according to your layout
    maxWidth: 200,
    height: 30,
    borderRadius: 10,
  };
  const filtersContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    margin: "20px 0", // Adjust as needed for top and bottom margin
  };

  const labelStyle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: 17,
    //paddingTop: "15px",
    //paddingBottom: "5px",
    paddingRight: 10,
  };

  const buttonStyles: React.CSSProperties = {
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    borderRadius: "10px",
    padding: "8px 10px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  };

  const filteredEntries = warehouseEntries.filter((entry) => {
    // Apply both filters: check if the entry's barcode includes the barcode filter
    // and if the entry's name includes the name filter.
    const matchesBarcode =
      !barcodeInput.trim() || entry.barcode.includes(barcodeInput.trim());
    const matchesName =
      !nameInput.trim() ||
      entry.name.toLowerCase().includes(nameInput.trim().toLowerCase());
    return matchesBarcode && matchesName;
  });

  if (!isLoggedIn && !isAccessedFromSidebar) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed", // Keep this fixed to overlay the screen
          top: 0,
          left: 0,
          width: "100vw", // Use viewport width to fill the screen horizontally
          height: "100%", // Changed from '100vh' to '100%'
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            maxWidth: "300px",
          }}
        >
          <h2 style={{ marginBottom: "20px", textAlign: "center" }}>Login</h2>
          <input
            type="text"
            placeholder="Enter login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              boxSizing: "border-box",
              fontSize: "16px", // Add this line
            }}
          />
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "10px",
              boxSizing: "border-box",
              fontSize: "16px", // Add this line
            }}
          />
          <button
            onClick={handleLogin}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px", // Add this line
            }}
          >
            Login
          </button>
          <button
            onClick={handleForgotPassword}
            style={{
              marginTop: "5px",
              backgroundColor: "transparent",
              border: "none",
              color: "#007bff",
              cursor: "pointer",
              fontSize: "12px", // Add this line
            }}
          >
            Forgot Password?
          </button>
        </div>
      </div>
    );
  }

  return (
    <GradientContainer>
      <div style={headerStyles}>
        <div style={titleAndCartContainerStyles}>
          <h1 style={{ fontSize: 35, margin: "0" }}>{translations.Products}</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedStoreId !== "admin" &&
              selectedStoreId !== "admin1" &&
              selectedStoreId !== "admin2" && (
                <div style={{ position: "relative", marginRight: "30px" }}>
                  <FaFileInvoiceDollar
                    onClick={toggleProfileModal}
                    size={34}
                    style={{ cursor: "pointer" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-20px",
                      right: "-15px",
                      backgroundColor: "#007bff",
                      color: "white",
                      borderRadius: "10px",
                      padding: "5px 10px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Invoices
                  </div>
                </div>
              )}

            <div style={{ position: "relative" }}>
              <FaShoppingCart onClick={toggleCartModal} size={34} />
              {totalItems > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "50%",
                    width: "30px",
                    height: "25px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "800",
                    fontSize: "14px",
                  }}
                >
                  {totalItems}
                </span>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "-20px",
                  right: "-15px",
                  backgroundColor: "#007bff",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                £{totalPrice.toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        <div style={filtersContainerStyles}>
          <div style={{ position: "relative", flex: "1" }}>
            {showDropdown && (
              <div ref={dropdownRef} style={dropdownStyles}>
                {/* Render "All Categories" option */}
                <div
                  onClick={() => handleCategorySelect("All")}
                  style={{ padding: "10px", cursor: "pointer" }}
                >
                  All Categories
                </div>
                {/* Render category options */}
                {categories.map((category) => (
                  <div
                    key={category.id}
                    onClick={() => handleCategorySelect(category.Category)}
                    style={{ padding: "10px", cursor: "pointer" }}
                  >
                    {category.Category}
                  </div>
                ))}
              </div>
            )}
            <div onClick={toggleDropdown} style={categoryIndicatorStyles}>
              <FaBars size={24} style={{ marginRight: "10px" }} />
              <span>
                {selectedCategory === "All"
                  ? "All Categories"
                  : categories.find((cat) => cat.Category === selectedCategory)
                      ?.Category || "All Categories"}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {isMobile ? (
              // Render filter icon on mobile
              <div style={{ position: "relative" }}>
                <FaFilter
                  size={24}
                  onClick={toggleFilterModal}
                  style={{ position: "relative" }}
                />
                {(barcodeInput.trim() !== "" || nameInput.trim() !== "") && (
               <FaExclamationCircle
               size={16}
               style={{
                 position: "absolute",
                 top: -5,
                 right: -5,
                 color: "red", // Applying red color to the icon
                 fontSize: "10px", // Size of the icon (use the fontSize property to adjust the icon size)
               }}
             />
                )}
              </div>
            ) : (
              // Render inputs on non-mobile
              <>
                <label style={labelStyle}>Filter Barcode:</label>
                <input
                  style={{ ...inputStyle, marginRight: "1rem" }}
                  placeholder="Barcode Filter"
                  value={barcodeInput}
                  onChange={handleBarcodeInputChange}
                  ref={barcodeInputRef}
                />

                <label style={labelStyle}>Filter Name:</label>
                <input
                  style={{ ...inputStyle, marginRight: "1rem" }}
                  placeholder="Name Filter"
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
              </>
            )}
            {(selectedStoreId === "admin" ||
              selectedStoreId === "admin1" ||
              selectedStoreId === "admin2") && (
              <button style={buttonStyles} onClick={toggleFastScanMode}>
                {isFastScanMode ? "Exit Fast Scan" : "Fast Scan"}
              </button>
            )}
          </div>
        </div>
      </div>

      <div style={scrollableContainerStyles}>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              height: "100%",
              fontSize: 40,
            }}
          >
            <p>Loading...</p>
          </div>
        ) : (
          <div style={gridStyles}>
            {filteredEntries.map((product) => (
              <ProductCard
                cartItems={cartItems}
                key={product.id}
                product={product}
                setAddToCartProduct={setAddToCartProduct}
              />
            ))}
          </div>
        )}
        {hasMore && (
          <div
            ref={observerTargetRef}
            style={{ marginTop: -1000, height: "1000px", zIndex: 1000 }}
          />
        )}
      </div>

      {isModalOpen && (
        <AddWarehouseModal isOpen={isModalOpen} onClose={onCloseModal} />
      )}
      <CartModal
        isOpen={isCartModalOpen}
        onClose={toggleCartModal}
        cartItems={cartItems}
        //clearCart={clearCart} // Pass the clearCart function as a prop
        selectedStoreId={selectedStoreId}
        addToCart={addToCart} // Pass the addToCart function as a prop
      />

      <AddToCartModal
        isOpen={addToCartProduct !== null}
        onClose={() => setAddToCartProduct(null)}
        product={addToCartProduct}
        cartItems={cartItems}
        selectedStoreId={selectedStoreId}
        addToCart={addToCart} // Pass the addToCart function as a prop
      />
      <ProfileModal isOpen={isProfileModalOpen} onClose={toggleProfileModal} />

      <ScanSuccessModal
        isOpen={isScanSuccessModalOpen}
        onClose={() => {
          setIsScanSuccessModalOpen(false);
          setNoMoreQuantitiesAvailable(false); // Reset noMoreQuantitiesAvailable to false
          setIsFastScanMode(false); // Exit Fast Scan mode when closing the modal
        }}
        product={scannedProduct}
        onBarcodeScan={handleBarcodeScan} // Pass the scan handler
        cartItems={cartItems} // Pass the entire cartItems array
        noMoreQuantitiesAvailable={noMoreQuantitiesAvailable} // Pass as prop
        resetNoMoreQuantitiesAvailable={() =>
          setNoMoreQuantitiesAvailable(false)
        } // Pass reset function
      />
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        barcode={barcodeInput}
        setBarcode={setBarcodeInput}
        name={nameInput}
        setName={setNameInput}
      />
    </GradientContainer>
  );
};

export default Products;

const FilterModal = ({
  isOpen,
  onClose,
  barcode,
  setBarcode,
  name,
  setName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          width: "70%", // Set a specific width for the modal
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "80%",
          margin: "0 auto", // Automatically adjust the margin on both sides to center
        }}
      >
        <h2>Filter Products</h2>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          <label>Filter Barcode:</label>
          <input
            type="text"
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            placeholder="Barcode Filter"
            style={{
              padding: "8px",
              boxSizing: "border-box",
              width: "100%",
              fontSize: 16,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "10px",
          }}
        >
          <label>Filter Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name Filter"
            style={{
              padding: "8px",
              boxSizing: "border-box",
              width: "100%",
              fontSize: 16,
            }}
          />
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <button
          onClick={onClose}
          style={{
            backgroundColor: "#007bff", // Primary button color
            color: "white", // Text color
            border: "none", // Remove default border
            borderRadius: "5px", // Rounded corners
            padding: "10px 15px", // Padding inside the button
            cursor: "pointer", // Cursor on hover
            fontSize: "16px", // Text size
            outline: "none", // Remove focus outline
            transition: "background-color 0.2s", // Smooth transition for hover effect
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          } // Darker color on hover
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          } // Original color on mouse out
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

const ProductCard = ({ product, setAddToCartProduct, cartItems }: any) => {
  const inCart =
    cartItems.find((item) => item.docId === product.id)?.quantity || 0;

  const cardStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #ddd",
    backgroundColor: "white",
    margin: "0px 0px", // This will create space on both sides of each element
    //justifyContent:'flex-end',
    //borderRadius: "15px",
    //padding: 20,
    //paddingTop: "5px",
    //paddingBottom: "20px",
    //paddingRight: "10%",
    //paddingLeft:'10%',
    //margin: "10px",
    //backgroundColor: "#fff",
    border: "3px solid black", // Added a black border of 1px
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };

  const imageStyles: React.CSSProperties = {
    display: "flex", // Ensures the use of Flexbox
    flex: 1,
    width: "100%",
    maxHeight: "100px",
    //border: "1px solid black", // Added a black border of 1px
    overflow: "hidden",
    objectFit: "cover", // Maintain aspect ratio and cover the container
    justifyContent: "center", // Centers content horizontally
    alignItems: "center", // Centers content vertically
  };

  const image: React.CSSProperties = {
    width: "auto",
    height: "100%", // Height auto ensures the image scales with its natural aspect ratio
    objectFit: "contain",
    overflow: "hidden",
  };

  const quantityPriceStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between", // This will create space between the quantity and price
    margin: "0px 0px", // This will create space on both sides of each element
    textAlign: "center", // Center-align the text
  };

  // Style for individual quantity and price elements
  const quantityPriceItemStyles: React.CSSProperties = {
    margin: "0px 0px", // This will create space on both sides of each element
    textAlign: "center", // Center-align the text
    fontSize: 14,
    fontWeight: 800,
  };
  const outOfStockStyles: React.CSSProperties = {
    color: "red",
    fontWeight: "bold",
    //margin: "0 20px", // Similar margin as quantityPriceItemStyles for alignment
    textAlign: "center",
  };

  const priceItemStyles: React.CSSProperties = {
    //marginTop: "15px", // This will create space on both sides of each element
    textAlign: "center", // Center-align the text
    fontSize: 14,
    margin: "0px 0px", // This will create space on both sides of each element
    fontWeight: 800,
  };

  // Style to reduce gap between name and description
  const nameStyles: React.CSSProperties = {
    margin: "0px 0px", // This will create space on both sides of each element
    //fontWeight:400,
    //paddingLeft: "8px",
    //paddingRight: "8px",
    fontSize: 14,
    textAlign: "center", // Center-align the text
    lineHeight: "1.2",
    wordWrap: "break-all", // Ensures words break to prevent overflow
  };

  const descriptionStyles: React.CSSProperties = {
    margin: "0px 0px", // This will create space on both sides of each element
    //paddingLeft: "10px",
    //paddingRight: "10px",
    fontSize: 14,
    textAlign: "center", // Center-align the text
    lineHeight: "1.2",
    wordWrap: "break-all", // Ensures words break to prevent overflow
  };

  const buttonStyles: React.CSSProperties = {
    fontSize: "16px",
    width: "100%",
    fontWeight: "500",
    backgroundColor: "#007bff", // Primary color
    color: "white",
    //borderRadius: "10px",
    padding: "10px 0px",
    border: "none",
    cursor: "pointer",
    outline: "none",
    transition: "background-color 0.2s ease",
  };

  const detailsContainerStyles: React.CSSProperties = {
    padding: "0.5rem 0rem",
    //maxHeight:'70%',
    width: "100%", // Takes full width of its parent to align children properly
    display: "flex",
    flex: 1.5,
    flexDirection: "column",
    justifyContent: "space-between", // Ensures top and bottom sections are spaced out fully
    alignItems: "center", // Center child elements horizontally
  };

  const upperDetailsStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  };

  const lowerDetailsStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  };

  return (
    <div style={cardStyles}>
      <div style={imageStyles}>
        <img
          src={formatBase64Image(product.imageUrl)}
          alt={product.name}
          style={image}
        />
      </div>

      <div style={detailsContainerStyles}>
        <div style={upperDetailsStyles}>
          <h3 style={nameStyles}>{product.name}</h3>
          <p style={descriptionStyles}>{product.description}</p>
          <p style={descriptionStyles}>{product.barcode}</p>
        </div>
        <div style={lowerDetailsStyles}>
          <div style={quantityPriceStyles}>
            {product.boxQuantity > 0 && (
              <p style={quantityPriceItemStyles}>
                Box Quantity: {product.boxQuantity}
              </p>
            )}
            <p
              style={
                product.quantity > 0
                  ? quantityPriceItemStyles
                  : outOfStockStyles
              }
            >
              {product.quantity > 0
                ? `Quantity: ${product.quantity}`
                : "Out of Stock"}
            </p>
          </div>
          <p style={priceItemStyles}>Price: £{product.unitPrice}</p>

          {inCart > 0 && (
            <p
              style={
                ([descriptionStyles],
                {
                  bottom: "10px",
                  right: "10px",
                  backgroundColor: "purple",
                  color: "white",
                  borderRadius: "10px",
                  padding: "5px 10px",
                  fontSize: "18px",
                  fontWeight: "500",
                  textAlign: "center",
                })
              }
            >
              In Cart: {inCart}
            </p> // Display how many are in the cart
          )}

          {/* Additional product details can be added here */}
        </div>
      </div>
      <button style={buttonStyles} onClick={() => setAddToCartProduct(product)}>
        Add to Cart
      </button>
    </div>
  );
};
