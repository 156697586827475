import React from "react";
import { BiSort } from "react-icons/bi";
import { FaImage } from "react-icons/fa";

interface RowHeaderProps {
  titles: {
    [key: string]: string;
  };
  percentages: {
    [key: string]: string;
  };
}

const RowHeader: React.FC<RowHeaderProps> = ({ titles, percentages }) => {
  const headerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "space-between",
    backgroundColor: '#1c345d',
    color: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "13px",
    fontFamily: "PoppinsBold",

    paddingLeft:10,
  };

  

  return (
    <div style={headerStyles}>
      {Object.entries(titles).map(([key, title], index) => (
        <>
          <span key={key} style={{ flexBasis: percentages[key] }}>
            <span>{title}</span>
            
            {index === 0 ? <FaImage style={{ marginLeft: 5, color: 'white' }} /> : <BiSort style={{ marginLeft: 5, color: 'white' }} />}

          </span>
        </>
      ))}
    </div>
  );
};

export default RowHeader;
