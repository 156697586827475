// ProspectDetailsModal.jsx
//@ts-nocheck
import React, { CSSProperties, useState } from "react";
import { db } from "../App";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getAuth, updateEmail } from "firebase/auth";

interface ProspectDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  prospect: {
    id: string;
    name: string;
    address: string;
    email: string; // Add email field
    password: string; // Add password field
    phoneNumber: string; // Add phoneNumber field
    archived: boolean; // Add archived field
  };
}

const ProspectDetailsModal: React.FC<ProspectDetailsModalProps> = ({
  isOpen,
  onClose,
  prospect,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(prospect.phoneNumber); // Initialize with current phone number
  const [email, setEmail] = useState(prospect.email);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
  const handleUpdateEmail = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch('/update-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: prospect.id, newEmail: email.trim() }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        alert(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error updating email: ', error);
      setErrorMessage("Failed to update email. " + error.message);
    } finally {
      setIsUpdating(false);
    }
  };*/
  

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (!isOpen || !prospect) return null;

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).substring(2, 8);
    return randomPassword;
  };

  const handleUpdatePassword = async () => {
    const newPassword = generateRandomPassword();
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);
    try {
      await updateDoc(prospectRef, {
        password: newPassword, // Update the password field with the new random password
      });
      alert(
        `Successfully updated password for ${prospect.name}. New password: ${newPassword}`
      );
    } catch (error) {
      console.error("Error updating password: ", error);
      alert("Failed to update password.");
    }
  };

  const handleDelete = async () => {
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);
    try {
      await deleteDoc(prospectRef);
      alert(`Successfully deleted ${prospect.name}.`);
      onClose(); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting prospect: ", error);
      alert("Failed to delete prospect.");
    }
  };

  const handleArchiveToggle = async () => {
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);
    try {
      await updateDoc(prospectRef, {
        archived: !prospect.archived, // Toggle the 'archived' field
      });
      alert(
        `Successfully ${prospect.archived ? "unarchived" : "archived"} ${
          prospect.name
        }.`
      );
      onClose(); // Close the modal after archiving/unarchiving
    } catch (error) {
      console.error(
        `Error ${prospect.archived ? "unarchiving" : "archiving"} prospect: `,
        error
      );
      alert(`Failed to ${prospect.archived ? "unarchive" : "archive"} prospect.`);
    }
  };

  const handleUpdatePhoneNumber = async () => {
    if (!prospect.id) {
      alert("Prospect details are missing.");
      return;
    }

    const prospectRef = doc(db, "Prospects", prospect.id);
    try {
      await updateDoc(prospectRef, {
        phoneNumber: phoneNumber.trim(), // Update the phoneNumber field
      });
      alert(`Successfully updated phone number for ${prospect.name}.`);
    } catch (error) {
      console.error("Error updating phone number: ", error);
      alert("Failed to update phone number.");
    }
  };

  return (
    <div style={modalStyles.background}>
      <div style={modalStyles.container}>
        <h2>Prospect Details</h2>
        <p>Name: {prospect.name}</p>
        <p>Address: {prospect.address}</p>
        <p>
          Number:{" "}
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </p>
        <p>Email: {prospect.email}</p> {/* Updated line */}

        {/*
       <p>
          Password: {showPassword ? prospect.password : "******"}{" "}
          <button onClick={togglePasswordVisibility}>
            {showPassword ? "Hide" : "Show"} Password
          </button>
  </p>

        <button style={modalStyles.buttonStyle} onClick={handleUpdatePassword}>
            Update Password
          </button>
  */}
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

        <div style={modalStyles.buttonContainer}>
          <button
            style={modalStyles.buttonStyle}
            onClick={handleUpdatePhoneNumber}
          >
            Update Phone Number
          </button>

          {/*
          <button
            style={modalStyles.buttonStyle}
            onClick={handleUpdateEmail}
            disabled={isUpdating}
          >
            Update Email
          </button>
*/}
          <button style={modalStyles.buttonStyle} onClick={handleArchiveToggle}>
            {prospect.archived ? "Unarchive" : "Archive"} Prospect
          </button>
          <button style={modalStyles.buttonStyle} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const modalStyles = {
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  container: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "500px",
    width: "80%",
    maxHeight: "90%",
    overflowY: "auto",
  },
  buttonStyle: {
    padding: "10px 20px",
    margin: "5px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

export default ProspectDetailsModal;
