import React, { useEffect, useState, CSSProperties } from "react";
import "./Dashboard.css";
import { db } from "../App";
import { collection, doc, getDoc, onSnapshot, getDocs, query, where } from "firebase/firestore";
import CartAdminModal from "./CartAdminModal";

interface CartItem {
  id: string;
  quantity: number;
  unitPrice?: number;
}

interface Cart {
  id: string;
  storeName: string;
  items: CartItem[];
  totalAmount: number;
}

const CartsAdmin: React.FC = () => {
  const [carts, setCarts] = useState<Cart[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCart, setSelectedCart] = useState<Cart | null>(null);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(collection(db, "Cart"), async (snapshot) => {
      const cartsList: Cart[] = await Promise.all(
        snapshot.docs.map(async (cartDoc) => {
          let storeName = "admin"; 
          if (cartDoc.id !== "admin") {
            const prospectDocRef = doc(db, "Prospects", cartDoc.id);
            const prospectDoc = await getDoc(prospectDocRef);
            if (prospectDoc.exists()) {
              storeName = prospectDoc.data().name;
            }
          }
          const items: CartItem[] = cartDoc.data().items || [];
          const itemDetails = await fetchItemDetails(items);
          const totalAmount = calculateTotalAmount(itemDetails);
          return {
            id: cartDoc.id,
            storeName,
            items: itemDetails,
            totalAmount,
          };
        })
      );
      setCarts(cartsList);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchItemDetails = async (items: CartItem[]): Promise<CartItem[]> => {
    if (items.length === 0) return [];

    const itemIds = items.map(item => item.id);
    const chunkSize = 30;
    const chunks = [];

    for (let i = 0; i < itemIds.length; i += chunkSize) {
      chunks.push(itemIds.slice(i, i + chunkSize));
    }

    const itemDetailsMap = new Map<string, any>();

    await Promise.all(chunks.map(async (chunk) => {
      const q = query(collection(db, "Warehouse"), where("__name__", "in", chunk));
      const itemSnapshots = await getDocs(q);
      itemSnapshots.forEach(doc => {
        itemDetailsMap.set(doc.id, doc.data());
      });
    }));

    // Return items with their details correctly mapped
    return items.map(item => {
      const itemDetail = itemDetailsMap.get(item.id);
      return {
        ...item,
        unitPrice: itemDetail ? itemDetail.unitPrice : item.unitPrice, // Use fetched unit price if available
        //imageUrl: itemDetail ? itemDetail.imageUrl : item.imageUrl, // Use fetched image URL if available
      };
    });
  };

  const calculateTotalAmount = (items: CartItem[]): number => {
    return items.reduce((total, item) => {
      return total + ((item.unitPrice || 0) * item.quantity);
    }, 0);
  };

  const handleOpenCartModal = (cart: Cart) => {
    setSelectedCart(cart);
    setIsCartModalOpen(true);
  };

  const handleCloseCartModal = () => {
    setSelectedCart(null);
    setIsCartModalOpen(false);
  };

  const gridContainerStyle: CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
    gap: "60px 20px",
    padding: "20px",
  };

  const cartCardStyle: CSSProperties = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    height: "100%",
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
          marginLeft: 40,
        }}
      >
        <h1>Carts</h1>
      </div>
      <div style={gridContainerStyle}>
        {isLoading ? (
          <p>Loading...</p>
        ) : carts.length > 0 ? (
          carts.map((cart) => (
            <div key={cart.id} style={cartCardStyle} onClick={() => handleOpenCartModal(cart)}>
              <h3>Store Name: {cart.storeName}</h3>
              <p>Number of Items: {cart.items.length}</p>
              <p>Total Amount: £{cart.totalAmount.toFixed(2)}</p>
            </div>
          ))
        ) : (
          <p>No carts found.</p>
        )}
      </div>
      {selectedCart && (
        <CartAdminModal
          isOpen={isCartModalOpen}
          onClose={handleCloseCartModal}
          cartId={selectedCart.id}
        />
      )}
    </div>
  );
};

export default CartsAdmin;
